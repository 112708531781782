<template>
  <div class="component-command" ref="commandRef" :class="{ folded: folded, deactivated: !active }" :position="position">
    <div class="history" ref="historyRef" :class="{ none: !state.history.list.length }">
      <ul>
        <li :key="idx" v-for="(r, idx) in state.history.list" v-html="r"></li>
      </ul>
    </div>
    <div class="text" ref="textRef">
      <textarea
        :placeholder="store.state.site.lang === 'ko' ? '명령어를 입력하세요. Alt + C 를 누르면 포커스됩니다.' : 'Enter a command. / Press Alt + C to focus.'"
        @keypress="keyPressInTextarea"
        @keydown="keyDownInTextarea"
        @keyup="keyUpInTextarea"
        v-model="state.text"
        spellcheck="false"
        :disabled="!state.loaded"
        ref="textareaRef"
      ></textarea>
      <ul class="assist" v-if="state.assist.list.length" :style="state.assist.style" ref="assistRef">
        <li v-for="(s, idx) in state.assist.list" :class="{ active: idx === state.assist.selectedIdx }" :key="idx" @click="clickAssist(s)">{{ s }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import httpLib from "../libs/httpLib";
import commLib from "../libs/commonLib";
import defines from "../defines.json";

export default {
  props: {
    page: String,
    callback: Function,
    active: Boolean,
    folded: Boolean,
    position: String,
    login: Boolean,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      init: false,
      text: "",
      loaded: true,
      assist: {
        selectedIdx: null,
        list: [],
        style: {
          top: 0,
          marginTop: 0,
          marginLeft: 0,
          maxHeight: "none",
        },
      },
      history: {
        index: 0,
        list: [],
      },
      processes: [],
    });

    const defined = defines.commands;

    const consoleBeforeLoginCmds = defined.console.beforeLogin.map((x) => {
      return x.cmd;
    });

    const consoleBeforePageCmds = defined.console.beforePage.map((x) => {
      return x.cmd;
    });

    const consoleAfterPageCmds = defined.console.afterPage.map((x) => {
      return x.cmd;
    });

    const consoleWordDefines = [...defined.console.beforeLogin, ...defined.console.beforePage, ...defined.console.afterPage];
    const consoleWordDefinesCmds = [...consoleBeforeLoginCmds, ...consoleBeforePageCmds, ...consoleAfterPageCmds];

    const nodeWordDefines = [...defined.node.beforeLogin, ...defined.node.beforePage, ...defined.node.afterPage];
    const nodeWordDefinesCmds = nodeWordDefines.map((x) => {
      return x.cmd;
    });

    const commandRef = ref(null);
    const textareaRef = ref(null);
    const assistRef = ref(null);
    const historyRef = ref(null);
    const textRef = ref(null);

    const runCommands = (command) => {
      const commandLines = commLib.getStrToArray(command, "\n", ";");

      for (let x of commandLines) {
        const cmd = x.trim();

        if (cmd) {
          state.processes.push(cmd);
        }
      }

      state.text = "";
      runCommand();
    };

    const appendLastHistory = (text) => {
      if (text && text.length) {
        state.history.list[state.history.list.length - 1] += `\n${text}\n`;
      }
    };

    const reloadCode = (seq) => {
      if (store.state.refs.console.state.activeTab === "code") {
        store.state.refs.console.editorRef.setCode(seq);
      }
    };

    const clearHistory = () => {
      state.history.list = [];
      state.history.index = 0;
      state.processes = [];
    };

    const selectComponents = (categoryName) => {
      const consoleRef = store.state.refs.console;

      if (categoryName) {
        for (let c of consoleRef.myComponentBoxRef.state.categories) {
          if (c.name === categoryName) {
            consoleRef.selectTab("my-components", { focus: true });
            consoleRef.myComponentBoxRef.setCategory(c);
            return;
          }
        }

        for (let c of consoleRef.componentBoxRef.state.categories) {
          if (c.name === categoryName) {
            consoleRef.selectTab("components", { focus: true });
            consoleRef.componentBoxRef.setCategory(c);
            return;
          }
        }
      }

      consoleRef.selectTab("components", { focus: true });
      consoleRef.componentBoxRef.setCategory(consoleRef.componentBoxRef.state.categories[0]);
      return;
    };

    const selectTemplates = (templateCategory) => {
      const consoleRef = store.state.refs.console;

      if (templateCategory) {
        for (let c of consoleRef.myTemplateBoxRef.state.categories) {
          if (c === templateCategory) {
            consoleRef.selectTab("my-templates", { focus: true });
            consoleRef.myTemplateBoxRef.setCategory(c);
            return;
          }
        }

        for (let c of consoleRef.templateBoxRef.state.categories) {
          if (c === templateCategory) {
            consoleRef.selectTab("templates", { focus: true });
            consoleRef.templateBoxRef.setCategory(c);
            return;
          }
        }
      }

      consoleRef.selectTab("my-templates", { focus: true });
      return;
    };

    const exit = () => {
      clearHistory();
      store.state.refs.console.selectTab("command", { fold: true });
      store.state.refs.webview?.postMessage("remove-session-storage");
      props.callback("exit");
    };

    const runCommand = (seq, hashmap) => {
      let command, commandWords, args;
      const consoleRef = store.state.refs.console;

      if (!seq) {
        seq = store.state.page.seq;

        if (!seq) {
          return;
        }
      }

      if (!state.processes.length) {
        if (!props.page) {
          return;
        }

        store.state.refs.webview.load(seq, true);
        reloadCode(seq);
        return;
      }

      command = state.processes.splice(0, 1)[0].trim();
      command = commLib.getTextReplacedDoubleSpacesToSingle(command);
      commandWords = command.split(" ");

      state.history.list.push(command);
      state.history.index = state.history.list.length;

      if (!props.login && !consoleBeforeLoginCmds.includes(commandWords[0])) {
        state.processes = [];
        commLib.message.show("warning", store.state.site.lang === "ko" ? "알 수 없는 명령입니다." : "Unknown command.");
        return;
      }

      if (!props.page && ![...consoleBeforeLoginCmds, ...consoleBeforePageCmds].includes(commandWords[0])) {
        state.processes = [];
        commLib.message.show("warning", store.state.site.lang === "ko" ? "알 수 없는 명령입니다." : "Unknown command.");
        return;
      }

      if (commandWords[0].startsWith("@")) {
        if (commandWords.length < 2 || !nodeWordDefinesCmds.includes(commandWords[1])) {
          state.processes = [];
          commLib.message.show("warning", store.state.site.lang === "ko" ? "알 수 없는 명령입니다." : "Unknown command.");
          return;
        }
      } else if (!consoleWordDefinesCmds.includes(commandWords[0])) {
        state.processes = [];
        commLib.message.show("warning", store.state.site.lang === "ko" ? "알 수 없는 명령입니다." : "Unknown command.");
        return;
      }

      args = {
        page: props.page,
        seq: seq,
      };

      if (commandWords[0].startsWith("@")) {
        const cmd = nodeWordDefines.find((x) => {
          return x.cmd === commandWords[1];
        });

        if (commandWords.length < cmd.min || commandWords.length > cmd.max) {
          return commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 인자의 개수가 올바르지 않습니다." : "The number of arguments entered is not correct.");
        }

        if (cmd.component) {
          let num;

          if (!hashmap) {
            hashmap = commLib.getHashmapFromCookie();
          }

          if (commandWords.length < 4) {
            if (commandWords.length === 2) {
              selectComponents();
            } else {
              selectComponents(commandWords[2].trim());
            }

            state.text = command.trim() + " ";
            return;
          }

          num = 1;

          if (hashmap[commandWords[2]]) {
            num = hashmap[commandWords[2]] + 1;
          }

          args.num = num;
          hashmap[commandWords[2]] = num;
        } else {
          switch (commandWords[1]) {
            case "template":
              if (commandWords.length < 4) {
                if (commandWords.length === 2) {
                  selectTemplates();
                } else {
                  selectTemplates(commandWords[2].trim());
                }

                state.text = command.trim() + " ";
                return;
              }
              break;

            case "save":
              if (!commLib.isOnlyAlphabetString(commandWords[2])) {
                return commLib.message.show("warning", store.state.site.lang === "ko" ? "컴포넌트의 카테고리는 영문으로만 입력해주세요." : "Please enter the component category in English only.");
              } else if (!commLib.isNumeric(commandWords[3])) {
                return commLib.message.show("warning", store.state.site.lang === "ko" ? "컴포넌트의 번호를 숫자로 입력해주세요." : "Please enter the number of component in number only.");
              }

              store.state.refs.webview.postMessage("capture-node", {
                nid: commandWords[0].substring(1),
                category: commandWords[2].toLowerCase(),
                num: commandWords[3],
              });
              return;

            case "code": {
              let nid = "";
              let lang = "";

              if (commandWords.length > 1) {
                nid = commandWords[0].replace("@", "");

                if (commandWords.length > 2) {
                  lang = commandWords[2];
                }
              }

              store.commit("setPage", {
                ...store.state.page,
                nid: nid ? nid : "root",
              });

              if (consoleRef.state.activeTab !== "code") {
                consoleRef.selectTab("code");
              }

              consoleRef.editorRef.setCode();

              if (lang) {
                nextTick(() => {
                  switch (lang.trim()) {
                    case "css":
                      consoleRef.editorRef.selectLang("css");
                      break;

                    case "js":
                    case "javascript":
                      consoleRef.editorRef.selectLang("javascript");
                      break;

                    default:
                      consoleRef.editorRef.selectLang("html");
                      break;
                  }
                });
              }

              state.processes = [];
              return;
            }

            default:
              break;
          }
        }
      } else if (commandWords[0].startsWith("#")) {
        return runCommand();
      } else {
        const cmd = consoleWordDefines.find((x) => {
          return x.cmd === commandWords[0];
        });

        if (commandWords.length < cmd.min || commandWords.length > cmd.max) {
          return commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 인자의 개수가 올바르지 않습니다." : "The number of arguments entered is not correct.");
        }

        switch (commandWords[0]) {
          case "vi": {
            const error = () => {
              commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 이름의 페이지가 존재하지 않습니다." : "The page does not exist.");
            };

            httpLib
              .get(`/api/pages/details/${commandWords[1]}`)
              .then((res) => {
                if (res && res.data && typeof res.data === "object" && Object.keys(res.data).length) {
                  const obj = res.data;
                  obj.created = new Date(obj.created * 1000).toString();
                  obj.updated = new Date(obj.updated * 1000).toString();
                  appendLastHistory(JSON.stringify(obj, null, 4));
                } else {
                  error();
                }
              })
              .catch(() => {
                error();
              });

            state.processes = [];
            return;
          }

          case "join":
            if (store.state.account.login) {
              return commLib.message.show("warning", store.state.site.lang === "ko" ? "로그아웃 후 진행해주세요." : "Allowed after logout.");
            }

            props.callback("open-modal", "join");
            return;

          case "login":
            if (store.state.account.login) {
              return commLib.message.show("warning", store.state.site.lang === "ko" ? "로그아웃 후 진행해주세요." : "Allowed after logout.");
            }

            props.callback("open-modal", "login");
            return;

          case "passwd":
            props.callback("open-modal", "password");
            return;

          case "logout":
            httpLib
              .post("/api/account/logout")
              .then(() => {
                exit();
                commLib.message.show("success", store.state.site.lang === "ko" ? "로그아웃하였습니다." : "Logged out successfully.");
                props.callback("set-account");
              })
              .catch((e) => {
                console.log(e);
                commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
              });
            return;

          case "rm": {
            if (commandWords[1] && commandWords[1].trim() && !confirm(`입력하신 명령을 실행하시겠습니까?`)) {
              return;
            }
            break;
          }

          case "exit": {
            exit();
            return;
          }

          case "export":
            if (commandWords.length < 2 && !store.state.page.name) {
              commLib.message.show("warning", store.state.site.lang === "ko" ? "페이지 이름을 입력해주세요." : "The page name is required.");
              return;
            } else if (commandWords.length > 1) {
              const pageName = commandWords[1].replace(".json", "");

              if (!store.state.pages.includes(pageName)) {
                commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 페이지가 존재하지 않습니다." : "The page does not exist.");
                return;
              }

              args.page = pageName;
              args.seq = null;
            }

            commLib.postForm(`${store.state.site.url}/pages/export`, args);
            return;

          case "del":
            if (confirm(store.state.site.lang === "ko" ? "해당 항목을 삭제하시겠습니까?" : "Are you sure you want to delete the item?")) {
              httpLib
                .delete(`/api/components/${commandWords[1]}/${commandWords[2]}/${commandWords[3]}`)
                .then(() => {
                  commLib.message.show("success", store.state.site.lang === "ko" ? "선택하신 항목을 삭제하였습니다." : " The item has been deleted.");

                  if (commandWords[1] === "component") {
                    consoleRef.myComponentBoxRef.state.init = false;

                    if (consoleRef.state.activeTab === "my-components") {
                      consoleRef.myComponentBoxRef.init();
                    }
                  } else if (commandWords[1] === "template") {
                    consoleRef.myTemplateBoxRef.state.init = false;

                    if (consoleRef.state.activeTab === "my-templates") {
                      consoleRef.myTemplateBoxRef.init();
                    }
                  }

                  props.callback("set-component-categories");
                  props.callback("renew-resource");
                })
                .catch((err) => {
                  switch (err.response?.status) {
                    case 404:
                      commLib.message.show("warning", store.state.site.lang === "ko" ? "선택하신 항목이 존재하지 않습니다." : "The item does not exist.");
                      break;

                    default:
                      commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                      break;
                  }
                });
            }
            return;

          case "undo":
            if (store.state.page.seq > 1) {
              const seq = store.state.page.seq - 1;
              store.state.refs.webview.load(seq);
              reloadCode(seq);
            }

            state.processes = [];
            return;

          case "redo":
            if (store.state.page.seq < store.state.page.maxSeq) {
              const seq = store.state.page.seq + 1;
              store.state.refs.webview.load(seq);
              reloadCode(seq);
            }

            state.processes = [];
            return;

          case "reset": {
            if (!commandWords.includes("--y") && !confirm(store.state.site.lang === "ko" ? "페이지를 초기화하시겠습니까?" : "Are you sure you want to reset the page?")) {
              return;
            }
            break;
          }

          case "open": {
            let pageName = commandWords[1].replace(".json", "");

            if (location.hash) {
              const hashArr = location.hash.split("/");

              if (hashArr.length > 1 && pageName === hashArr[1] && !location.hash.endsWith("/")) {
                pageName += "/";
              }
            }

            props.callback("go", pageName);
            return;
          }

          case "cls":
          case "clear":
            clearHistory();
            return;

          case "help": {
            const texts = [];
            const htmls = [];

            if (commandWords.length > 1) {
              loop: for (let i1 in defines.commands) {
                for (let i2 in defines.commands[i1]) {
                  for (let i3 in defines.commands[i1][i2]) {
                    if (defines.commands[i1][i2][i3].cmd === commandWords[1].trim()) {
                      const command = defines.commands[i1][i2][i3];
                      texts.push(`${command.desc[store.state.site.lang]}`);

                      if (command.args.length) {
                        texts.push(`- args : ${command.args.join(" ")}`);
                      }

                      texts.push(`- examples : ${command.examples.join(" / ")}`);
                      break loop;
                    }
                  }
                }
              }

              if (!texts.length) {
                commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 명령어가 존재하지 않습니다." : "The command does not exist.");
              }
            } else {
              for (let i1 in defines.commands) {
                const commands = [];

                if (i1 === "node") {
                  texts.push("");
                }

                texts.push(`(${i1} commands)`);

                for (let i2 in defines.commands[i1]) {
                  for (let c of defines.commands[i1][i2]) {
                    commands.push(c);
                  }
                }

                commands.sort((a, b) => {
                  if (a.cmd > b.cmd) {
                    return 1;
                  } else if (a.cmd < b.cmd) {
                    return -1;
                  } else {
                    return 0;
                  }
                });

                for (let c of commands) {
                  texts.push(`${c.cmd} : ${c.desc[store.state.site.lang]}`);
                }
              }

              htmls.push(`<div style="margin-bottom:5px;">(video manuals)</div>`);

              for (let v of defines.help.videos) {
                htmls.push(...[`<div class="video play-video" val="${v}">`, `<img src="https://img.youtube.com/vi/${v}/mqdefault.jpg"  />`, `<i class="fa fa-play"></i>`, `</div>`]);
              }
            }

            appendLastHistory(texts.join("\n"));
            appendLastHistory(htmls.join(""));
            return;
          }

          default:
            break;
        }
      }

      args.cmd = command;
      state.loaded = false;

      httpLib
        .post("/api/commands/push", args)
        .then((res) => {
          state.loaded = true;

          switch (res.data) {
            case "pushed":
              if (commandWords[0].startsWith("@")) {
                switch (commandWords[1]) {
                  case "remove":
                    props.callback("set-nid", "root");
                    break;
                }
              }

              runCommand(seq + 1, hashmap);
              break;

            case "created":
              commLib.message.show("success", store.state.site.lang === "ko" ? "새로운 페이지를 생성하였습니다." : "A new page has been created.");
              props.callback("close-modal");
              props.callback("renew-resource");
              props.callback("set-pages");
              break;

            case "reseted":
              store.state.refs.webview.load(1, true);
              consoleRef.selectTab("command", { fold: true });
              store.state.refs.console.reloadComponents();
              commLib.message.show("success", store.state.site.lang === "ko" ? "페이지를 초기화하였습니다." : "The page has been initialized.");
              break;

            case "copied":
              commLib.message.show("success", store.state.site.lang === "ko" ? "입력하신 항목을 복사하였습니다." : "The item has been copied.");
              props.callback("renew-resource");
              props.callback("set-pages");
              break;

            case "renamed":
              commLib.message.show("success", store.state.site.lang === "ko" ? "입력하신 항목의 이름을 변경하였습니다." : "The item has been renamed.");
              props.callback("exit");
              props.callback("set-pages");
              break;

            case "removed":
              commLib.message.show("success", store.state.site.lang === "ko" ? "입력하신 항목을 삭제하였습니다." : " The item has been deleted.");
              props.callback("exit");
              props.callback("renew-resource");
              props.callback("set-pages");
              break;
          }

          switch (commandWords[0]) {
            case "ls": {
              let append = "";

              if (Array.isArray(res.data)) {
                append = res.data.join("\n");
              }

              appendLastHistory(append);
              break;
            }
          }

          if (!commandWords[0].startsWith("@")) {
            state.processes = [];
          }

          nextTick(() => {
            focus();
          });
        })
        .catch((err) => {
          state.loaded = true;
          state.processes = [];

          switch (err.response?.status) {
            case 400:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "알 수 없는 명령입니다." : "Unknown command.");
              break;

            case 401:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "로그인이 필요합니다." : "Login is required.");
              props.callback("open-modal", "login");
              return;

            case 403:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "실행할 수 없는 명령입니다." : "The command cannot be executed.");
              break;

            case 404:
              switch (err.response.data) {
                case "component":
                  commLib.message.show("warning", store.state.site.lang === "ko" ? "해당 컴포넌트가 존재하지 않습니다." : "The component does not exist.");
                  break;

                case "node":
                  commLib.message.show("warning", store.state.site.lang === "ko" ? "해당 노드가 존재하지 않습니다." : "The node does not exist.");
                  break;

                case "item":
                  commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 이름의 항목이 없습니다." : "The item does not exist.");
                  break;

                case "page":
                  commLib.message.show("error", store.state.site.lang === "ko" ? "페이지가 존재하지 않습니다." : "The page does not exist.");
                  break;

                default:
                  commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                  break;
              }
              break;

            case 409:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "동일한 이름을 가진 항목이 있습니다." : "There is an item with a duplicate name.");
              break;

            case 422:
              switch (err.response.data) {
                case "name":
                  commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 항목의 이름이 유효하지 않습니다." : "Invalid item name.");
                  break;

                case "wrong":
                  commLib.message.show("warning", store.state.site.lang === "ko" ? "인자 값이 다르거나 명령어가 정확하지 않습니다." : "The arguments are incorrect or the command is incorrect");
                  break;

                default:
                  commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                  break;
              }
              break;

            case 507:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "사용 가능한 리소스 크기를 초과하였습니다. 관리자에게 문의해주세요." : "The available resource size has been exceeded. Please contact the administrator.");
              break;

            default:
              commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
              break;
          }

          nextTick(() => {
            focus();
          });
        });
    };

    const clickAssist = (word) => {
      enterLast(word);
      focus();
    };

    const enterLast = (word) => {
      const texts = getTexts();
      const wordDefines = state.text.startsWith("@") ? nodeWordDefines : consoleWordDefines;
      const wordDefine = wordDefines.find((x) => x.cmd === word);
      const lastWord = texts[texts.length - 1];
      let suffix = "";

      if (lastWord.startsWith("@")) {
        suffix = " ";
      } else if (wordDefine?.space) {
        if (word === "export" && store.state.page.name) {
          suffix = "";
        } else {
          suffix = " ";
        }
      } else if (isCategory(texts)) {
        suffix = " ";
      }

      texts[texts.length - 1] = word;
      state.text = texts.join(" ") + suffix;
    };

    const isCategory = (texts) => {
      if (state.text.startsWith("@") && texts.length > 1) {
        const define = nodeWordDefines.find((x) => x.cmd === texts[texts.length - 2]);
        return define && define.component;
      }

      return false;
    };

    const isCategoryNum = (texts) => {
      if (state.text.startsWith("@") && texts.length > 1) {
        const define = nodeWordDefines.find((x) => x.cmd === texts[texts.length - 3]);
        return define && define.component;
      }

      return false;
    };

    const getTexts = () => {
      return commLib.getTextReplacedDoubleSpacesToSingle(state.text).split(" ");
    };

    const getAssistWords = (texts) => {
      const result = [];
      const key = state.text.startsWith("@") ? "node" : "console";
      const lastWord = texts[texts.length - 1];

      if (isCategory(texts)) {
        const categories = store.state.components.map((c) => c.category);

        for (let c of categories) {
          if (c.startsWith(lastWord)) {
            result.push(c);
          }
        }

        return result;
      }

      if (isCategoryNum(texts)) {
        const component = store.state.components.find((c) => c.category === texts[2]);

        if (component) {
          const nums = component.list.map((i) => i.num);
          const uniqueNums = [...new Set(nums)];

          for (let n of uniqueNums) {
            const num = n.toString();

            if (num.startsWith(lastWord)) {
              result.push(num);
            }
          }
        }

        return result;
      }

      const runnableCommands = getRunnableCommands(key);
      const wordDefines = state.text.startsWith("@") ? nodeWordDefines : consoleWordDefines;
      const wordDefine = wordDefines.find((x) => x.cmd === (key === "node" ? texts[1] : texts[0]));

      if (key === "node" ? (wordDefine ? wordDefine.max >= texts.length && wordDefine.component : true) : texts.length === 1) {
        for (let c of runnableCommands) {
          if (c.startsWith(lastWord)) {
            result.push(c);
          }
        }
      }

      const consoleWordCms = consoleWordDefines
        .filter((x) => {
          return x.item;
        })
        .map((x) => {
          return x.cmd;
        })
        .filter((x) => {
          return state.text.startsWith(x);
        });

      if (consoleWordCms.length) {
        const texts = getTexts();

        if (texts.length < 2) {
          return result;
        } else if (!runnableCommands.includes(texts[0])) {
          return result;
        }

        // const target = texts[1].trim();

        // if (!target) {
        //   return result;
        // }

        const wordDefine = consoleWordDefines.find((x) => x.cmd === texts[0]);

        if (wordDefine && texts.length <= wordDefine.max) {
          for (let p of store.state.pages) {
            if (texts.length === 1 || p.startsWith(lastWord)) {
              result.push(p);
            }
          }
        }

        return result;
      }

      if (!store.state.page.name) {
        return result;
      }

      if (lastWord.startsWith("@")) {
        const nids = getNids(lastWord);

        for (let nid of nids) {
          result.push(nid);
        }
      }

      return result;
    };

    const getNids = (word) => {
      const result = [];

      for (let nid of store.state.page.nids) {
        if (nid && nid.startsWith(word.substring(1))) {
          result.push(`@${nid}`);
        }
      }

      return result;
    };

    const getRunnableCommands = (key) => {
      const result = [];

      if (!props.login) {
        result.push(
          ...defined[key].beforeLogin.map((x) => {
            return x.cmd;
          })
        );
      } else if (!props.page) {
        result.push(
          ...defined[key].beforePage.map((x) => {
            return x.cmd;
          }),
          ...defined[key].beforeLogin.map((x) => {
            return x.cmd;
          })
        );
      } else {
        result.push(
          ...defined[key].afterPage.map((x) => {
            return x.cmd;
          }),
          ...defined[key].beforePage.map((x) => {
            return x.cmd;
          }),
          ...defined[key].beforeLogin.map((x) => {
            return x.cmd;
          })
        );
      }

      return result;
    };

    const keyPressInTextarea = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        const texts = getTexts();
        const lastWord = texts[texts.length - 1];

        e.preventDefault();

        if (
          state.assist.list.length &&
          state.assist.selectedIdx !== null &&
          state.assist.list[state.assist.selectedIdx] &&
          state.assist.list[state.assist.selectedIdx].startsWith(lastWord) &&
          lastWord !== state.assist.list[state.assist.selectedIdx]
        ) {
          enterLast(state.assist.list[state.assist.selectedIdx]);
          state.assist.list = [];
          state.assist.selectedIdx = null;
          setAssist();
          return;
        }

        if (state.text) {
          runCommands(state.text.trim());
        } else {
          state.history.list.push("");
        }

        state.assist.list = [];
        state.assist.selectedIdx = null;
      }
    };

    const setAssist = () => {
      const texts = getTexts();
      state.assist.list = [];
      state.assist.selectedIdx = null;
      state.assist.style.top = 0;
      state.assist.style.marginTop = 0;
      state.assist.style.marginLeft = 0;
      state.assist.style.maxHeight = "none";

      if (!texts.length || !texts[0]) {
        return;
      }

      const assistWords = getAssistWords(texts);

      if (!assistWords.length) {
        return;
      }

      const lastWord = texts[texts.length - 1];

      if (lastWord === assistWords[assistWords.length - 1]) {
        return;
      }

      for (let w of assistWords) {
        state.assist.list.push(w);
      }

      const posLeft = texts.filter((t, i) => i !== texts.length - 1).join(" ").length;
      const belowHeight = textRef.value.querySelector("textarea").offsetHeight - 21;
      const aboveHeight = commandRef.value.parentNode.offsetHeight - belowHeight;

      state.assist.selectedIdx = 0;
      state.assist.style.marginLeft = posLeft * 7 - (posLeft ? 0 : 5) + "px";
      state.assist.style.maxHeight = (aboveHeight > belowHeight ? aboveHeight : belowHeight) + "px";

      nextTick(() => {
        if (aboveHeight < belowHeight) {
          state.assist.style.top = "35px";
        } else {
          state.assist.style.marginTop = assistRef.value.offsetHeight * -1 + "px";
        }
      });
    };

    const keyUpInTextarea = (e) => {
      if (["@", " ", "Backspace"].includes(e.key) || (e.key.length === 1 && e.key.match(/^[a-z0-9]+$/i))) {
        setAssist();
      }
    };

    const keyDownInTextarea = (e) => {
      if (e.ctrlKey && e.key === "c" && !commLib.isTextSelected(textareaRef.value)) {
        state.text = "";
      } else if (e.key === "ArrowUp") {
        if (state.assist.list.length) {
          if (state.assist.selectedIdx != null && state.assist.selectedIdx > 0) {
            state.assist.selectedIdx -= 1;
          } else {
            state.assist.selectedIdx = state.assist.list.length - 1;
          }

          e.preventDefault();
          return;
        }

        const arr = e.target.value.split("\n");
        const index = state.history.index - 1;

        if (arr[0].length < e.target.selectionStart) {
          return;
        } else if (state.history.index === 0) {
          return;
        }

        state.history.index = index;
        state.text = state.history.list[index];

        if (state.text.includes("\n")) {
          const arr = state.text.split("\n");
          state.text = arr[0];
        }

        e.preventDefault();
      } else if (e.key === "ArrowDown") {
        if (state.assist.list.length) {
          if (state.assist.selectedIdx !== null && state.assist.selectedIdx < state.assist.list.length - 1) {
            state.assist.selectedIdx += 1;
          } else {
            state.assist.selectedIdx = 0;
          }

          e.preventDefault();
          return;
        }

        const value = e.target.value;
        const arr = value.split("\n");

        if (value.length - arr[arr.length - 1].length > e.target.selectionEnd) {
          return;
        }

        if (state.history.index >= state.history.list.length - 1) {
          state.history.index = state.history.list.length;
          state.text = "";
        } else {
          const index = state.history.index + 1;

          state.history.index = index;
          state.text = state.history.list[index];

          if (state.text.includes("\n")) {
            const arr = state.text.split("\n");
            state.text = arr[0];
          }

          e.preventDefault();
        }
      } else if (e.key === "Tab") {
        const texts = getTexts();
        const lastWord = texts[texts.length - 1];

        e.preventDefault();

        if (
          state.assist.list.length &&
          state.assist.selectedIdx !== null &&
          state.assist.list[state.assist.selectedIdx] &&
          state.assist.list[state.assist.selectedIdx].startsWith(lastWord) &&
          lastWord !== state.assist.list[state.assist.selectedIdx]
        ) {
          enterLast(state.assist.list[state.assist.selectedIdx]);
          setAssist();
          return;
        } else if (!texts.length || !texts[0]) {
          return;
        }

        const assistWords = getAssistWords(texts);

        if (!assistWords.length) {
          return;
        }

        enterLast(assistWords[0]);
      }
    };

    const focus = () => {
      textareaRef.value.focus();
    };

    const resize = () => {
      let result;
      const resultMinHeight = 37;

      historyRef.value.style.height = "auto";
      result = commandRef.value.offsetHeight - historyRef.value.getBoundingClientRect().height;

      if (result < resultMinHeight) {
        result = resultMinHeight;
        historyRef.value.style.height = commandRef.value.offsetHeight - resultMinHeight + "px";
      }

      textRef.value.style.height = result + "px";
      historyRef.value.scrollTop = historyRef.value.scrollHeight;
    };

    const adjust = () => {
      resize();
      focus();
    };

    const init = () => {
      if (state.init) {
        return;
      }

      adjust();
      state.init = true;

      /////// temp start ///////
      if (process.env.NODE_ENV === "development" && location.hash.replace("#/", "")) {
        let arr = [];
        arr = [
          "reset --y",
          `
          @root append tabs 600
          @root append tabs 610
         `,
        ];

        const tempRun = (commands) => {
          if (commands.length) {
            setTimeout(() => {
              state.text = commands.splice(0, 1)[0];
              keyPressInTextarea({ key: "Enter", preventDefault: function() {} });

              if (commands.length) {
                tempRun(commands);
              }
            }, 1000);
          }
        };

        tempRun(arr);
      }
      /////// temp end ///////
    };

    onMounted(() => {
      setTimeout(() => {
        init();
      }, 250);
    });

    return {
      store,
      state,
      init,
      commandRef,
      textareaRef,
      assistRef,
      historyRef,
      textRef,
      adjust,
      focus,
      keyPressInTextarea,
      keyDownInTextarea,
      keyUpInTextarea,
      resize,
      runCommand,
      runCommands,
      clickAssist,
    };
  },
  watch: {
    "state.history.list": {
      handler() {
        nextTick(() => {
          this.resize();
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.component-command {
  color: #fff;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  > .history {
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    > ul {
      list-style: none;
      padding: 16px 16px 0 15px;
      margin: 0;

      > li {
        min-height: 21px;
        white-space: pre-line;
        position: relative;
        padding-left: 14px;

        .video {
          cursor: pointer;
          display: inline-block;
          margin-bottom: 1px;
          position: relative;
          border: 1px solid #3c3c3c;
          margin: 0 5px 5px 0;

          img {
            filter: brightness(0.5);
            height: 80px;
          }

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            font-size: 25px;
            margin-top: -10px;
            margin-left: -12.5px;
          }

          &:hover {
            img {
              filter: brightness(0.75);
            }
          }
        }

        &:before {
          position: absolute;
          left: 0;
          top: 3px;
          font-size: 11px;
          content: ">";
          color: #fff;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &.none > ul {
      padding-top: 6px;
    }
  }

  > .text {
    padding: 8px 6px 6px 29px;
    position: relative;

    > textarea {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 0;
      background: inherit;
      padding: 0;
      color: #fff;
      font-size: inherit;
      line-height: 1.76;
      resize: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &:hover,
      &:focus {
        border: 0;
        outline: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .assist {
      position: absolute;
      list-style: none;
      margin: 0;
      top: 35px;
      left: 25px;
      background: #000;
      outline: 1px solid #2e2e2e;
      padding: 0;
      min-width: 100px;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      > li {
        padding: 5px 10px;
        cursor: pointer;
        border: 1px solid transparent;

        &.active {
          border-color: #aaa;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &:before {
      position: absolute;
      left: 15px;
      top: 12px;
      font-size: 11px;
      content: ">";
      line-height: 1.7;
      color: #757575;
    }
  }

  &.deactivated {
    height: 41px;
    border-top: 1px solid #3c3c3c;
    position: absolute;
    top: auto;
    bottom: 0;
    width: 100%;
    padding-bottom: 0;
    z-index: 1;

    > .history {
      display: none;
    }

    > .text {
      height: 100% !important;
    }
  }

  &.folded {
    &[position="side"] {
      height: 100%;

      > .text {
        padding: 15px 2px;

        &:before {
          content: none;
        }
      }
    }
  }
}
</style>
