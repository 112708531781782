import { createStore } from "vuex";

export default createStore({
    state: {
        site: {
            host: "localhost",
            url: "http://localhost:2142",
            lang: "ko"
        },
        account: {
            mid: 0,
            email: "",
            server: "",
            resource: 0,
            login: false,
        },
        page: {
            name: null,
            seq: 1,
            maxSeq: 1,
            nid: "root",
            nids: []
        },
        pages: [],
        refs: {
            console: null,
            webview: null
        },
        toggle: {
            tag: true,
            mobile: false
        },
        components: [],
    },
    mutations: {
        setRefs(state, refs) {
            state.refs = refs;
        },
        setPage(state, page) {
            state.page = page;
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setToggle(state, payload) {
            if (payload.tag !== undefined) {
                state.toggle.tag = payload.tag;
            }

            if (payload.mobile !== undefined) {
                state.toggle.mobile = payload.mobile;
            }
        },
        setComponents(state, components) {
            state.components = components;
        }
    }
})