<template>
  <div class="modal-login">
    <div class="pt-4 pb-4 text-center">
      <h1 class="font-paint color-point">Publessing</h1>
      <h3 class="font-coding">
        <template v-if="state.mode === 'login'">{{ store.state.site.lang === "ko" ? "로그인" : "Login with email" }}</template>
        <template v-else>{{ store.state.site.lang === "ko" ? "패스워드 초기화" : "Reset password" }}</template>
      </h3>
    </div>
    <div class="form mt-2">
      <div class="mb-4">
        <label for="email">{{ store.state.site.lang === "ko" ? "이메일" : "Email" }}</label>
        <input type="email" class="form-control" id="email" v-model="state.account.email" placeholder="name@example.com" autocomplete="off" @keypress.enter="state.mode === 'login' ? login() : reset()" :disabled="!state.loaded" ref="emailRef" />
      </div>
      <div class="mb-4" v-if="state.mode === 'login'">
        <label for="password">{{ store.state.site.lang === "ko" ? "패스워드" : "Password" }}</label>
        <input type="password" class="form-control" id="password" v-model="state.account.password" @keypress.enter="login()" :disabled="!state.loaded" />
      </div>
      <div class="labels mb-4" v-if="state.mode === 'login'">
        <div class="form-check">
          <label class="foat-left form-check-label" for="remember">
            <input type="checkbox" class="form-check-input" id="remember" v-model="state.account.remember" value="true" @keypress.enter="login()" :disabled="!state.loaded" />
            <span>{{ store.state.site.lang === "ko" ? "로그인 유지" : "Remember me" }}</span>
          </label>
          <label class="reset float-right" @click="changeMode('reset')">{{ store.state.site.lang === "ko" ? "패스워드를 분실하셨나요?" : "Forgot password?" }}</label>
        </div>
      </div>
      <template v-if="state.mode === 'login'">
        <button class="btn-block btn-point no-radius" type="button" @click="login()" :disabled="!state.loaded">{{ store.state.site.lang === "ko" ? "로그인" : "Login" }}</button>
        <div class="bottom">
          <label :class="{ disabled: !state.loaded }" @click="state.loaded && callback('join')">{{ store.state.site.lang === "ko" ? "회원가입" : "Create a new account" }}</label>
        </div>
      </template>
      <template v-else>
        <button class="btn-block btn-point no-radius" type="button" @click="reset()" :disabled="!state.loaded">
          <template v-if="store.state.site.lang === 'ko'">{{ `${state.loaded ? "패스워드 초기화 URL 발송" : "발송 중..."}` }}</template>
          <template v-else>{{ `${state.loaded ? "Send password reset URL" : "Sending..."}` }}</template>
        </button>
        <div class="bottom">
          <label :class="{ disabled: !state.loaded }" @click="changeMode('login')">{{ store.state.site.lang === "ko" ? "로그인" : "Login" }}</label>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import httpLib from "../libs/httpLib";
import commLib from "../libs/commonLib";
import { useStore } from "vuex";

export default {
  props: {
    callback: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      mode: "login",
      loaded: true,
      account: {
        email: "",
        password: "",
        remember: false,
      },
    });

    const emailRef = ref(null);

    const changeMode = (mode) => {
      if (!state.loaded) {
        return;
      }

      state.mode = mode;

      if (mode === "reset") {
        document.getElementById("email").focus();
      }
    };

    const reset = () => {
      if (!state.loaded) {
        return;
      } else if (!state.account.email || !state.account.email.trim()) {
        document.getElementById("email").focus();
        return commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소를 입력해주세요." : "Email address is required.");
      }

      state.loaded = false;

      httpLib
        .post("/api/account/reset", state.account)
        .then(() => {
          state.loaded = true;
          commLib.message.show("success", store.state.site.lang === "ko" ? "입력하신 이메일로 패스워드 초기화 URL을 발송하였습니다." : "A password reset URL has been sent to the email address you entered.");
          props.callback("close-modal");
        })
        .catch((err) => {
          state.loaded = true;

          switch (err.response?.status) {
            case 404:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "해당 계정이 존재하지 않습니다. 정보를 다시 확인해주세요." : "The account does not exist. Check the information again.");
              break;

            case 422:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소가 올바르지 않습니다." : "The email address is incorrect.");
              break;

            case 429:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "잠시 후 다시 시도해주세요." : "Try again in a few minutes.");
              break;

            default:
              commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
              break;
          }
        });
    };

    const login = () => {
      if (!state.loaded) {
        return;
      } else if (!state.account.email || !state.account.email.trim()) {
        document.getElementById("email").focus();
        return commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소를 입력해주세요." : "Email address is required.");
      } else if (!state.account.password || !state.account.password.trim()) {
        document.getElementById("password").focus();
        return commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드를 입력해주세요." : "Password is required.");
      }

      state.loaded = false;

      httpLib
        .post("/api/account/login", state.account)
        .then(() => {
          state.loaded = true;
          commLib.message.show("success", store.state.site.lang === "ko" ? "로그인하였습니다." : "Log-in succeed.");
          props.callback("set-account");
          props.callback("close-modal");
          props.callback("exit");
        })
        .catch((err) => {
          state.loaded = true;

          switch (err.response?.status) {
            case 404:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "해당 계정이 존재하지 않습니다. 정보를 다시 확인해주세요." : "The account does not exist. Check the information again.");
              break;

            case 422:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소가 올바르지 않습니다." : "Email address is incorrect");
              break;

            case 429:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "잠시 후 로그인해주세요." : "Please log in after a while.");
              break;

            default:
              commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
              break;
          }
        });
    };

    onMounted(() => {
      setTimeout(() => {
        emailRef.value.focus();
      }, 250);
    });

    return { store, state, emailRef, login, reset, changeMode };
  },
};
</script>
<style lang="scss" scoped>
.modal-login {
  padding: 25px;

  h1 {
    font-weight: bold;
    font-size: 35px;
  }

  h3 {
    font-size: 25px;
    margin-top: 35px;
  }

  input:not([type="checkbox"]) {
    height: 50px;
    border-radius: 0;
    font-size: 14px;

    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: #146ece;
    }
  }

  label {
    font-size: 14px;

    &:not([for]) {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .labels {
    label {
      &.reset {
        margin-top: 2px;
      }
    }
  }

  .bottom {
    text-align: center;
    padding-top: 20px;

    > label {
      margin: 0;
      font-size: 14px;
    }
  }

  button {
    font-size: 14px;
    height: 50px;

    &[disabled] {
      opacity: 0.5;
    }
  }
}
</style>
