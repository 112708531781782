import axios from "axios";
import commLib from "./commonLib";
import store from "../store";

export default {
    get(url, parameters) {
        if (!parameters) {
            parameters = {};
        }

        parameters.lang = store.state.site.lang;

        let args = {
            params: parameters,
            withCredentials: true
        };

        return axios.get(store.state.site.url + url, args);
    },
    post(url, parameters) {
        let params = new URLSearchParams();

        const config = {
            withCredentials: true
        };

        if (typeof parameters === "function") {
            console.warn("parameters is parameters");
        }

        for (let i in parameters) {
            let val = parameters[i];

            if (typeof val === 'string')
                val = val.trim();

            params.append(i, val);
        }

        params.append("lang", store.state.site.lang);
        return axios.post(store.state.site.url + url, params, config);
    },
    delete: (url) => {
        const token = commLib.cookie.get("token");
        const config = {
            headers: { "Authorization": `Bearer ${token}` },
            withCredentials: true
        };

        return axios.delete(store.state.site.url + url, config);
    },
    postFile(url, formData) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        };

        return axios.post(store.state.site.url + url, formData, config);
    }
};