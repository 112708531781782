<template>
  <div class="component-console font-coding" :class="{ 'no-select': state.dragging, closed: state.folded }" ref="consoleRef" :style="state.style" :position="state.position">
    <div class="header clearfix">
      <div class="top">
        <a class="brand font-paint" @click="goHome()">P</a>
        <template v-if="store.state.account.login">
          <div class="email">
            <span class="badge">basic</span>
            <span>{{ store.state.account.email }}</span>
          </div>
          <div class="status">
            <div class="progress" :title="`${store.state.site.lang === 'ko' ? '리소스' : 'Resource'} (${store.state.account.resource} / ${define.custom.maxResource})`">
              <div class="progress-bar" :class="{ 'bg-danger': resourcePercent > 90 }" role="progressbar" :style="{ width: resourcePercent + '%' }" :aria-valuenow="resourcePercent" aria-valuemin="0" aria-valuemax="100">
                {{ store.state.account.resource }}
              </div>
            </div>
          </div>
        </template>
        <div class="required" v-else>{{ store.state.site.lang === "ko" ? "로그인이 필요합니다." : "Login is requried." }}</div>
      </div>
      <div class="addons">
        <Toggle text="tag" :active="store.state.toggle.tag" :toggle="toggleTag" :title="(store.state.site.lang === 'ko' ? '태그' : 'Tag') + ' (Alt + V)'" :disabled="!store.state.page.name" ref="toggleTagRef" />
        <Toggle text="mobile" :active="store.state.toggle.mobile" :toggle="toggleMobile" :title="(store.state.site.lang === 'ko' ? '모바일' : 'Mobile') + ' (Alt + M)'" :disabled="!store.state.page.name" ref="toggleMobileRef" />
        <span class="fa fa-repeat" :title="(store.state.site.lang === 'ko' ? '새로고침' : 'Refresh') + ' (Alt + R)'" :class="{ disabled: !store.state.page.name }" @click="callback('refresh')"></span>
        <span class="fa fa-backward" :title="(store.state.site.lang === 'ko' ? '실행 취소' : 'Undo') + ' (Alt + Z)'" :class="{ disabled: !store.state.page.name }" @click="callback('undo')"></span>
        <span class="fa fa-forward" :title="(store.state.site.lang === 'ko' ? '재실행' : 'Redo') + ' (Alt + Shift + Z)'" :class="{ disabled: !store.state.page.name }" @click="callback('redo')"></span>
        <span class="fa fa-comment-o" :title="store.state.site.lang === 'ko' ? '코멘트' : 'Comments'" @click="callback('open-modal', 'comment', 'large long')"></span>
        <span class="position" :title="(store.state.site.lang === 'ko' ? '위치 변경' : 'Change position') + ' (Alt + A)'" @click="changePosition()">
          <img src="/assets/img/ico.console.side.svg" v-if="state.position !== 'side'" alt="Change position (side)" />
          <img src="/assets/img/ico.console.bottom.svg" v-else-if="state.position !== 'bottom'" alt="Change position (bottom)" />
        </span>
        <span class="full" :title="(store.state.site.lang === 'ko' ? '전체 화면' : 'Full screen') + ' (Alt + F)'" @click="setFullScreen()">
          <img src="/assets/img/ico.console.full.svg" alt="Full screen" />
        </span>
        <span class="fold" :title="(!state.folded ? (store.state.site.lang === 'ko' ? '접어두기' : 'Fold') : store.state.site.lang === 'ko' ? '펴기' : 'Unfold') + ' (Alt + D)'" @click="fold()">
          <i class="fa fa-angle-right right"></i>
          <i class="fa fa-angle-left left"></i>
          <i class="fa fa-angle-down down"></i>
          <i class="fa fa-angle-up up"></i>
        </span>
      </div>
      <div class="tabs">
        <ul class="no-select">
          <li v-for="(t, idx) in tabs" :key="idx" :class="{ active: t === state.activeTab }">
            <button :disabled="t !== state.activeTab && !store.state.page.name ? true : false" @click="selectTab(t)" :title="t[0].toUpperCase() + t.substring(1) + ' (Alt + ' + (idx + 1) + ')'">
              <span>{{ t }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="core" :style="{ paddingBottom: state.activeTab === 'command' || state.folded ? 0 : '41px' }">
      <Command ref="commandRef" :page="store.state.page.name" :login="store.state.account.login" :position="state.position" :folded="state.folded" :callback="callback" :active="!state.folded && state.activeTab === 'command'" />
      <Editor :page="store.state.page.name" :position="state.position" :size="state.size" :callback="callback" v-show="state.activeTab === 'code'" ref="editorRef" />
      <File ref="fileRef" :page="store.state.page.name" :position="state.position" v-show="state.activeTab === 'files'" :callback="callback" :key="state.key" />
      <Box ref="componentBoxRef" sector="component" :size="state.size" :custom="false" :position="state.position" v-show="state.activeTab === 'components'" :callback="callback" :key="state.key" />
      <!-- <Box ref="templateBoxRef" sector="template" :size="state.size" :custom="false" :position="state.position" v-show="state.activeTab === 'templates'" :callback="callback" :key="state.key" /> -->
      <Box ref="myComponentBoxRef" sector="component" :size="state.size" :custom="true" :position="state.position" v-show="state.activeTab === 'my-components'" :callback="callback" :key="state.key" />
      <Box ref="myTemplateBoxRef" sector="template" :size="state.size" :custom="true" :position="state.position" v-show="state.activeTab === 'my-templates'" :callback="callback" :key="state.key" />
    </div>
    <div class="feedback" v-if="state.poped">
      <img :src="`/assets/img/message.feedback.${store.state.site.lang}.png`" @click="feedback()" />
      <span :title="store.state.site.lang === 'ko' ? '닫기' : 'Close'" @click="closeFeedback()">&times;</span>
    </div>
    <div class="grab" ref="grabRef" v-show="!state.folded && !state.fullScreen"></div>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Toggle from "./Toggle";
import Editor from "./Editor";
import File from "./File";
import Command from "./Command";
import Box from "./Box";
import commLib from "../libs/commonLib";
import define from "../defines.json";

export default {
  components: { Toggle, Command, Editor, File, Box },
  props: {
    callback: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      key: 1,
      activeTab: null,
      folded: false,
      fullScreen: false,
      dragging: false,
      poped: true,
      position: null,
      size: "",
      style: {
        width: null,
        height: null,
      },
    });

    const tabs = ["command", "code", "files", "components", "my-components", "my-templates"];

    const resourcePercent = computed(() => {
      const percent = (store.state.account.resource / define.custom.maxResource) * 100;

      if (percent === 0) {
        return 0;
      } else if (percent < 0.1) {
        return 0.1;
      } else {
        return percent.toFixed(1);
      }
    });

    const sideSize = 500;
    const sideMinSize = 420;
    const sideMaxSize = window.innerWidth - 300;

    const bottomSize = 370;
    const bottomMinSize = 260;
    const bottomMaxSize = window.innerHeight - 300;

    const defaultOpened = true;
    const defaultPosition = "side";
    const consoleOpened = commLib.cookie.get("consoleOpened");
    const consolePosition = commLib.cookie.get("consolePosition");

    // refs
    const grabRef = ref(null);
    const consoleRef = ref(null);
    const toggleTagRef = ref(null);
    const toggleMobileRef = ref(null);
    const textRef = ref(null);
    const commandRef = ref(null);
    const editorRef = ref(null);
    const fileRef = ref(null);
    const componentBoxRef = ref(null);
    // const templateBoxRef = ref(null);
    const myComponentBoxRef = ref(null);
    const myTemplateBoxRef = ref(null);

    // methods
    const goHome = () => {
      if (store.state.page.name && !confirm(store.state.site.lang === "ko" ? "현재 페이지를 닫고 홈 화면으로 이동하시겠습니까?" : "Are you sure you want to close the current page and go to the send email page?")) {
        return;
      }

      location.href = "/" + store.state.site.lang;
    };

    const feedback = () => {
      alert(store.state.site.lang === "ko" ? "이용에 불편을 드려 죄송합니다. 이메일을 보내주시면 빠르게 처리하겠습니다." : "We apologize for the inconvenience. Send us an email and we will fix it quickly.");

      if (store.state.page.name && !confirm(store.state.site.lang === "ko" ? "현재 페이지를 닫고 이메일 발송 페이지로 이동하시겠습니까?" : "Are you sure you want to close the current page and go to the send email page?")) {
        return;
      }

      location.href = `/${store.state.site.lang}/community`;
    };

    const closeFeedback = () => {
      state.poped = false;
    };

    const selectTab = (tab, prevent) => {
      if (!store.state.page.name) {
        return;
      }

      if (state.folded && (!prevent || !prevent.fold)) {
        fold();
      }

      if (state.activeTab === tab) {
        return;
      }

      state.activeTab = tab;

      switch (state.activeTab) {
        case "command":
          nextTick(() => {
            commandRef.value.adjust();
          });
          break;

        case "code":
          editorRef.value.init();
          break;

        case "files":
          fileRef.value.init();
          break;

        case "components":
          componentBoxRef.value.init();

          if (!prevent || !prevent.focus) {
            componentBoxRef.value.focus();
          }
          break;

        // case "templates":
        //   templateBoxRef.value.init();

        //   if (!prevent) {
        //     templateBoxRef.value.focus();
        //   }
        //   break;

        case "my-components":
          myComponentBoxRef.value.init();

          if (!prevent) {
            myComponentBoxRef.value.focus();
          }
          break;

        case "my-templates":
          myTemplateBoxRef.value.init();

          if (!prevent) {
            myTemplateBoxRef.value.focus();
          }
          break;

        default:
          break;
      }
    };

    const toggleTag = (active) => {
      store.commit("setToggle", {
        tag: active,
      });

      if (active) {
        commLib.cookie.remove("webviewTag", store.state.site.host);
        store.state.refs.webview.postMessage("set-tags");
        store.state.refs.webview.postMessage("remove-class", "no-tag");
      } else {
        commLib.cookie.set("webviewTag", "N", true, store.state.site.host);
        store.state.refs.webview.postMessage("add-class", "no-tag");
      }
    };

    const toggleMobile = (active) => {
      store.commit("setToggle", {
        mobile: active,
      });

      if (state.activeTab === "components") {
        componentBoxRef.value.init(true);
      } else {
        componentBoxRef.value.state.init = false;
      }

      if (active) {
        commLib.cookie.set("mobileTag", "Y");
      } else {
        commLib.cookie.remove("mobileTag");
      }
    };

    const fold = (fold) => {
      state.folded = fold === undefined ? !state.folded : fold;

      if (state.folded) {
        state.fullScreen = false;
      }

      if (state.position === "side") {
        let value = 0;

        if (!state.folded) {
          const sessionWidth = sessionStorage.getItem("consoleWidth");
          value = sessionWidth ? sessionWidth : `${sideSize}px`;
        } else {
          value = "60px";
        }

        state.style.height = "100%";
        state.style.width = value;
        props.callback("set-style", { paddingLeft: value, paddingBottom: 0 });
      } else {
        let value = 0;

        if (!state.folded) {
          const sessionHeight = sessionStorage.getItem("consoleHeight");
          value = sessionHeight ? sessionHeight : `${bottomSize}px`;
        } else {
          value = "90px";
        }

        state.style.height = value;
        state.style.width = "100%";
        props.callback("set-style", { paddingLeft: 0, paddingBottom: value });
      }

      if (state.activeTab === "command") {
        nextTick(() => {
          commandRef.value?.resize();
          commandRef.value?.textareaRef.focus();
        });
      }

      if (!state.folded === defaultOpened) {
        commLib.cookie.remove("consoleOpened");
      } else {
        commLib.cookie.set("consoleOpened", !state.folded ? "Y" : "N", null, 7);
      }

      setSize();
    };

    const setFullScreen = () => {
      state.fullScreen = !state.fullScreen;

      if (state.fullScreen) {
        state.folded = false;

        if (state.position === "side") {
          props.callback("set-style", { paddingLeft: 0 });
          state.style.width = "100%";
        } else {
          props.callback("set-style", { paddingBottom: 0 });
          state.style.height = "100%";
        }
      } else {
        if (state.position === "side") {
          const sessionWidth = sessionStorage.getItem("consoleWidth");
          const value = sessionWidth ? sessionWidth : `${sideSize}px`;
          props.callback("set-style", { paddingLeft: value });
          state.style.width = value;
        } else {
          const sessionHeight = sessionStorage.getItem("consoleHeight");
          const value = sessionHeight ? sessionHeight : `${bottomSize}px`;
          props.callback("set-style", { paddingBottom: value });
          state.style.height = value;
        }
      }

      if (state.activeTab === "command") {
        nextTick(() => {
          commandRef.value.resize();
        });
      }

      setSize();
    };

    const setSize = () => {
      nextTick(() => {
        const width = consoleRef.value.offsetWidth;

        if (width > 1920) {
          state.size = "xxl";
        } else if (width >= 1920) {
          state.size = "xl";
        } else if (width >= 1600) {
          state.size = "lg";
        } else if (width >= 1140) {
          state.size = "md";
        } else if (width >= 960) {
          state.size = "sm";
        } else if (width >= 640) {
          state.size = "xs";
        } else {
          state.size = "xxs";
        }
      });
    };

    const changePosition = (position) => {
      state.position = position === undefined ? (state.position === "side" ? "bottom" : "side") : position;
      fold(state.folded);

      if (state.activeTab === "command") {
        nextTick(() => {
          commandRef.value?.resize();
        });
      }

      if (state.position !== defaultPosition) {
        commLib.cookie.set("consolePosition", state.position);
      } else {
        commLib.cookie.remove("consolePosition");
      }
    };

    const reloadComponents = () => {
      state.key += 1;

      nextTick(() => {
        // editorRef.value.state.init = false;
        // fileRef.value.state.init = false;
        // componentBoxRef.value.state.init = false;
        // myComponentBoxRef.value.state.init = false;
        // myTemplateBoxRef.value.state.init = false;

        componentBoxRef.value.setCategories();
        myComponentBoxRef.value.setCategories();
        myTemplateBoxRef.value.setCategories();
      });
    };

    // created
    state.activeTab = tabs[0];
    state.position = defaultPosition;

    if (consolePosition) {
      changePosition(consolePosition);
    } else {
      changePosition(defaultPosition);
    }

    if (consoleOpened !== defaultOpened) {
      if (defaultOpened) {
        fold(consoleOpened === "N");
      } else {
        fold(consoleOpened !== "Y");
      }
    }

    // mounted
    onMounted(() => {
      let startX, startWidth, startY, startHeight;

      const initDrag = (e) => {
        if (state.position === "bottom") {
          startY = e.clientY;
          startHeight = parseInt(document.defaultView.getComputedStyle(consoleRef.value).height, 10);
        } else {
          startX = e.clientX;
          startWidth = parseInt(document.defaultView.getComputedStyle(consoleRef.value).width, 10);
        }

        if (store.state.page.name) {
          store.state.refs.webview.state.dragging = true;
        }

        state.dragging = true;

        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);

        for (let i of [...document.querySelectorAll("iframe")]) {
          i.classList.add("no-pointer-events");
        }
      };

      const doDrag = (e) => {
        if (state.position === "side") {
          let value = startWidth + e.clientX - startX;

          if (value < sideMinSize) value = sideMinSize;
          if (value > sideMaxSize) value = sideMaxSize;

          value += "px";
          props.callback("set-style", { paddingLeft: value });
          state.style.width = value;

          sessionStorage.setItem("consoleWidth", value);
        } else {
          let value = startHeight - e.clientY + startY;

          if (value < bottomMinSize) value = bottomMinSize;
          if (value > bottomMaxSize) value = bottomMaxSize;

          value += "px";
          props.callback("set-style", { paddingBottom: value });
          state.style.height = value;

          sessionStorage.setItem("consoleHeight", value);
        }

        setSize();
      };

      const stopDrag = () => {
        state.dragging = false;

        document.documentElement.removeEventListener("mousemove", doDrag, false);
        document.documentElement.removeEventListener("mouseup", stopDrag, false);

        if (state.activeTab === "command") {
          commandRef.value.resize();
        }

        if (store.state.page.name) {
          store.state.refs.webview.state.dragging = false;
        }

        for (let i of [...document.querySelectorAll("iframe")]) {
          i.classList.remove("no-pointer-events");
        }
      };

      grabRef.value.addEventListener("mousedown", initDrag, false);
    });

    const returnObj = {
      store,
      state,
      define,
      tabs,
      resourcePercent,
      consoleRef,
      commandRef,
      editorRef,
      fileRef,
      componentBoxRef,
      // templateBoxRef,
      myComponentBoxRef,
      myTemplateBoxRef,
      grabRef,
      textRef,
      toggleTagRef,
      toggleMobileRef,
      toggleTag,
      toggleMobile,
      fold,
      setFullScreen,
      changePosition,
      selectTab,
      reloadComponents,
      goHome,
      feedback,
      closeFeedback,
    };

    store.commit("setRefs", { ...store.state.refs, console: returnObj });
    return returnObj;
  },
};
</script>
<style lang="scss" scoped>
.component-console {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;

  > .header {
    position: absolute;
    background: #000;
    color: #fff;
    left: 0;
    top: 0;
    font-size: 14px;
    width: 100%;

    > .top {
      height: 60px;
      padding-left: 60px;
      color: #fff;
      position: relative;
      border-bottom: 1px solid #3c3c3c;
      overflow: hidden;

      .brand {
        position: absolute;
        color: #fff;
        top: 0;
        border-right: 1px solid #3c3c3c;
        left: 0;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
        height: 100%;
        width: 60px;
        text-align: center;
        line-height: 57px;
      }

      .email {
        float: left;
        padding-left: 15px;
        line-height: 60px;
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;

        .badge {
          background: #3a3a3a;
          border-radius: 0;
          padding: 4px;
          font-size: 11px;
          margin-bottom: 3px;
          margin-right: 7.5px;
        }
      }

      .status {
        float: right;
        width: 40%;
        padding: 20px 15px 15px 15px;

        .progress {
          border-radius: 0;
          max-width: 250px;
          margin-left: auto;
          height: 20px;

          .progress-bar {
            background-color: #3a3a3a;
            min-width: 30px;
          }
        }
      }

      .required {
        color: #777;
        line-height: 60px;
        padding: 0 20px;
      }
    }

    > .tabs {
      float: left;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        > li {
          display: inline-block;

          button {
            color: #aaa;
            display: inline-block;
            background: none;
            box-shadow: none;
            border-radius: 0;
            border: 0;
            padding: 14px;
            position: relative;

            &[disabled] {
              opacity: 0.5;
            }

            &:not([disabled]):hover {
              color: #fff;
            }
          }

          &.active {
            cursor: pointer;
            opacity: 1;

            > button {
              color: #fff;

              > span {
                position: relative;

                &:after {
                  content: " ";
                  display: table;
                  border-bottom: 1px solid #fff;
                  position: absolute;
                  left: 0;
                  width: 100%;
                  bottom: -7px;
                }
              }
            }
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    > .addons {
      float: right;
      padding: 5px 4px 5px 10px;
      position: relative;

      > div {
        margin: 8px 15px 5px 0;
      }

      > span {
        vertical-align: top;
        color: #fff;
        padding: 10px 8px;
        cursor: pointer;
        line-height: 1;
        font-size: 17px;
        display: inline-block;
        text-align: center;

        &.fold {
          font-size: 25px;
          margin-top: -4px;

          > i {
            display: none;
          }
        }

        &.position {
          > img {
            width: 19px;
            height: 18px;
          }
        }

        &.full {
          > img {
            width: 17px;
            height: 17px;
            position: relative;
            top: 1px;
          }
        }

        &.disabled {
          color: #aaa;
          opacity: 0.5;
        }
      }
    }
  }

  .feedback {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 7.5px;
    z-index: 2;

    img {
      cursor: pointer;
    }

    span {
      display: none;
      cursor: pointer;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      right: 5px;
      top: 5px;
      color: #fff;
      text-align: center;
      width: 24px;
      height: 24px;
      line-height: 24px;

      &:hover {
        font-weight: bold;
      }
    }

    &:hover {
      span {
        display: inline-block;
      }
    }
  }

  > .grab {
    cursor: n-resize;
    height: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  > .core {
    height: 100%;
    position: relative;
    background: #1e1e1e;

    > * {
      background: inherit;
    }
  }

  &.closed {
    > .header > .top {
      display: none;
    }

    .feedback {
      display: none;
    }
  }

  &[position="side"] {
    height: 100% !important;
    padding-top: 160px;

    > .header {
      height: 160px;
      padding: 0;

      > .addons {
        float: none;
        border-bottom: 1px solid #3c3c3c;
        height: 50px;
        text-align: right;

        > div {
          float: left;
        }

        > span {
          &.fold i {
            &.right {
              display: none;
            }

            &.left {
              display: inline-block;
            }
          }
        }
      }

      > .tabs {
        height: 50px;
        width: 100%;
        overflow: hidden;
      }
    }

    > .grab {
      cursor: e-resize;
      height: 100%;
      position: absolute;
      width: 10px;
    }

    &.closed {
      > .header {
        height: 100%;

        > .addons {
          height: 100%;
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;

          > div {
            width: 100%;
            margin-right: 0;
            float: none;

            &:nth-child(2) {
              margin-bottom: 12.5px;
            }
          }

          > span {
            display: block;

            &.fold i {
              &.right {
                display: inline-block;
              }

              &.left {
                display: none;
              }
            }
          }
        }
      }

      > .core {
        display: none;
      }
    }
  }

  &[position="bottom"] {
    width: 100% !important;
    padding-top: 110px;

    > .header {
      height: 110px;

      > .addons {
        height: 50px;

        > span {
          &.fold i {
            &.up {
              display: none;
            }

            &.down {
              display: inline-block;
            }
          }
        }
      }
    }

    &.closed {
      padding-top: 90px;

      > .header {
        height: 50px;

        > .addons {
          > span {
            &.fold i {
              &.up {
                display: inline-block;
              }

              &.down {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
