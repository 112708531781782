<template>
  <div class="modal-join">
    <div class="pt-4 pb-4 text-center">
      <h1 class="font-paint color-point">Publessing</h1>
      <h3 class="font-coding">{{ store.state.site.lang === "ko" ? "회원가입" : "Create account" }}</h3>
    </div>
    <div class="form mt-2">
      <template v-if="state.step <= 2">
        <div class="mb-4">
          <label for="email">{{ store.state.site.lang === "ko" ? "이메일" : "Email" }}</label>
          <input type="email" class="form-control" id="email" v-model="state.account.email" placeholder="name@example.com" autocomplete="off" @keypress.enter="send()" ref="emailRef" :disabled="!state.loaded || state.step !== 1" />
        </div>
      </template>
      <template v-if="state.step === 2">
        <div class="mb-4">
          <label for="code">{{ store.state.site.lang === "ko" ? "인증 코드" : "Verification code" }}</label>
          <input type="text" class="form-control text-center" id="code" v-model="state.account.code" @keyup="keyup($event)" @keypress.enter="verify()" autocomplete="off" maxlength="6" :disabled="!state.loaded" />
        </div>
      </template>
      <template v-else-if="state.step === 3">
        <div class="mb-4">
          <label for="password">{{ store.state.site.lang === "ko" ? "패스워드" : "Password" }}</label>
          <input type="password" class="form-control" id="password" v-model="state.account.password" @keypress.enter="create()" :disabled="!state.loaded" />
        </div>
        <div class="mb-4">
          <label for="confirmPassword">{{ store.state.site.lang === "ko" ? "패스워드 재입력" : "Repeat Password" }}</label>
          <input type="password" class="form-control" id="confirmPassword" v-model="state.account.confirmPassword" @keypress.enter="create()" :disabled="!state.loaded" />
        </div>
      </template>
      <div class="actions">
        <button class="btn btn-lg btn-block btn-point no-radius" type="button" v-if="!state.loaded" disabled>{{ store.state.site.lang === "ko" ? "잠시 기다려주세요..." : "Wait a moment..." }}</button>
        <button class="btn btn-lg btn-block btn-point no-radius" type="button" @click="send()" v-else-if="state.step === 1">{{ store.state.site.lang === "ko" ? "인증 코드 발송" : "Send verification code" }}</button>
        <button class="btn btn-lg btn-block btn-point no-radius" type="button" @click="verify()" v-else-if="state.step === 2">{{ store.state.site.lang === "ko" ? "이메일 인증" : "Verify email" }}</button>
        <button class="btn btn-lg btn-block btn-point no-radius" type="button" @click="create()" v-else-if="state.step === 3">{{ store.state.site.lang === "ko" ? "계정 생성" : "Create account" }}</button>
        <div class="bottom">
          <label @click="moveBack()" :class="{ disabled: !state.loaded }" v-if="state.step > 1">{{ store.state.site.lang === "ko" ? "이전 단계" : "Prev step" }}</label>
          <label @click="state.loaded && callback('login')" :class="{ disabled: !state.loaded }" v-else>{{ store.state.site.lang === "ko" ? "로그인" : "Login" }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick, onMounted, reactive, ref } from "vue";
import commLib from "../libs/commonLib";
import httpLib from "../libs/httpLib";
import { useStore } from "vuex";
export default {
  props: {
    callback: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      step: 1,
      loaded: true,
      account: {
        email: "",
        code: "",
        password: "",
        confirmPassword: "",
      },
    });

    const emailRef = ref(null);

    const moveBack = () => {
      if (!state.loaded) {
        return;
      }

      state.step -= 1;
    };

    const keyup = (e) => {
      if ((e.target.value && e.target.value.length === 6 && e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        verify();
      }
    };

    const send = () => {
      if (!state.loaded) {
        return;
      } else if (!state.account.email) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소를 입력해주세요." : "Email address is required.");
        document.getElementById("email").focus();
      } else if (!commLib.isValidEmail(state.account.email)) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소가 올바르지 않습니다." : "The email address is invalid.");
        document.getElementById("email").select();
      } else {
        state.loaded = false;

        httpLib
          .post("/api/account/send", { email: state.account.email })
          .then(() => {
            state.loaded = true;
            commLib.message.show("success", store.state.site.lang === "ko" ? "이메일로 보내드린 인증 코드를 입력해주세요." : "Please enter the verification code in the email.");
            state.step = 2;
            nextTick(() => {
              document.getElementById("code").focus();
            });
          })
          .catch((err) => {
            state.loaded = true;

            switch (err.response?.status) {
              case 404:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 코드가 유효하지 않습니다." : "The verification code is invalid.");
                break;

              case 409:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "이미 가입한 이메일 주소입니다." : "This is the email address you have already signed up for.");
                document.getElementById("email").focus();
                break;

              case 429:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 요청이 너무 많습니다. 잠시 후 다시 시도해주세요." : "Too many requests. Please try again in a few minutes.");
                break;

              default:
                commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                break;
            }
          });
      }
    };

    const verify = () => {
      const args = {
        email: state.account.email,
        code: state.account.code,
      };

      if (!state.loaded) {
        return;
      } else if (!args.code) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 코드를 입력해주세요." : "Please enter your verification code.");
        document.getElementById("code").focus();
        return;
      }

      state.loaded = false;

      httpLib
        .post("/api/account/verify", args)
        .then(() => {
          state.loaded = true;
          commLib.message.show("success", store.state.site.lang === "ko" ? "이메일 인증이 완료되었습니다. 패스워드를 입력해주세요." : "Email verification is complete. Please enter your password.");
          state.step = 3;

          nextTick(() => {
            document.getElementById("password").focus();
          });
        })
        .catch((err) => {
          state.loaded = true;

          switch (err.response?.status) {
            case 403:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 코드의 유효 기간이 지났습니다." : "The validation code has expired.");
              break;

            case 404:
              commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 코드가 유효하지 않습니다." : "The verification code is invalid.");
              nextTick(() => {
                document.getElementById("code").focus();
              });
              break;

            default:
              commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
              break;
          }
        });
    };

    const create = () => {
      if (!state.loaded) {
        return;
      } else if (!state.account.password) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드를 입력해주세요." : "Password is required.");
        document.getElementById("password").focus();
      } else if (!state.account.confirmPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드를 한 번 더 입력해주세요." : "Please repeat password.");
        document.getElementById("confirmPassword").focus();
      } else if (state.account.password !== state.account.confirmPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드를 동일하게 입력해주세요." : "Please enter repeat password correctly.");
        document.getElementById("confirmPassword").select();
      } else if (state.account.password.length < 8 || state.account.password.length > 21) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드는 8자리 이상 21자리 이하로 입력해주세요." : "Please enter a password between 8 and 21 digits.");
        document.getElementById("password").select();
      } else {
        state.loaded = false;

        httpLib
          .post("/api/account/join", state.account)
          .then(() => {
            state.loaded = true;
            commLib.message.show("success", store.state.site.lang === "ko" ? "가입을 완료하였습니다." : "You have successfully signed up. Please log in.");
            props.callback("set-account");
            props.callback("close-modal");
          })
          .catch((err) => {
            state.loaded = true;

            switch (err.response?.status) {
              case 403:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 코드의 유효 기간이 지났습니다." : "The validation code has expired.");
                break;

              case 404:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "인증 코드가 유효하지 않습니다." : "The verification code is invalid.");
                break;

              case 409:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "이미 가입한 이메일 주소입니다." : "You have already signed up for an email address.");
                break;

              default:
                commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                break;
            }
          });
      }
    };

    onMounted(() => {
      setTimeout(() => {
        emailRef.value.focus();
      }, 250);
    });

    return { store, state, emailRef, moveBack, keyup, send, verify, create };
  },
};
</script>
<style lang="scss" scoped>
.modal-join {
  padding: 25px;

  h1 {
    font-weight: bold;
    font-size: 35px;
  }

  h3 {
    font-size: 25px;
    margin-top: 35px;
  }

  label {
    font-size: 14px;

    &:not([for]) {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  input {
    height: 50px;
    border-radius: 0;
    font-size: 14px;

    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: #146ece;
    }
  }

  .actions {
    padding-top: 10px;

    button {
      font-size: 14px;
      height: 50px;
    }

    .bottom {
      text-align: center;
      padding-top: 20px;

      > label {
        margin: 0;
      }
    }
  }
}
</style>
