<template v-if="state.init">
  <div className="component-file">
    <div class="wrapper">
      <div class="tile attach" @click="attach()">
        <label>
          <span>+</span>
          <input type="file" class="hide" accept="image/png, image/gif, image/jpeg" multiple ref="fileRef" @change="attach($event)" />
        </label>
      </div>
      <div class="tile" v-for="(f, idx) in state.files" :key="idx" :style="{ backgroundImage: `url(${store.state.site.url}/files/${page}/${f.name})` }">
        <div class="body">
          <div class="title" :title="f.name">{{ f.name }}</div>
          <button class="copy" type="button" @click="copy(f.name)" :title="store.state.site.lang === 'ko' ? '클립보드에 경로 복사' : 'Copy path to clipboard'">Copy</button>
          <i class="expand fa fa-search" @click="zoom(idx)"></i>
          <i class="remove fa fa-trash-o" @click="remove(f.name)"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import defines from "../defines.json";
import commLib from "../libs/commonLib";
import httpLib from "../libs/httpLib";
import { useStore } from "vuex";

export default {
  props: {
    page: String,
    callback: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      init: false,
      files: [],
    });

    const fileRef = ref(null);

    const remove = (fileName) => {
      if (confirm(`선택하신 파일(${fileName})을 삭제하시겠습니까?`)) {
        httpLib
          .delete(`/api/files/${props.page}/` + fileName)
          .then(() => {
            commLib.message.show("success", store.state.site.lang === "ko" ? "선택하신 파일을 삭제하였습니다." : " The file has been deleted.");
            load();
            props.callback("renew-resource");
          })
          .catch((err) => {
            switch (err.response?.status) {
              case 404:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "선택하신 파일이 존재하지 않습니다." : "The file does not exist.");
                load();
                break;

              default:
                commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                break;
            }
          });
      }
    };

    const zoom = (idx) => {
      props.callback("open-modal", "imageViewer", "large", {
        idx: idx,
        list: state.files,
        page: props.page,
      });
    };

    const attach = (e) => {
      if (e?.target?.files?.length) {
        var formData = new FormData();

        for (let f of e.target.files) {
          formData.append("files", f);
        }

        httpLib
          .postFile(`/api/files/upload/${props.page}`, formData)
          .then(() => {
            fileRef.value.value = "";
            commLib.message.show("success", store.state.site.lang === "ko" ? "파일을 업로드하였습니다." : "The file has been uploaded.");
            load();
            props.callback("renew-resource");
          })
          .catch((err) => {
            fileRef.value.value = "";

            switch (err.response?.status) {
              case 403:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "유효하지 않은 파일이 있습니다." : "There is an invalid file");
                break;

              case 409:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "동일한 이름을 가진 파일이 있습니다." : "There is a file with a duplicate name.");
                break;

              case 413:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "파일의 용량이 너무 큽니다. 1MB 미만의 파일을 올려주세요." : "The file size is too large. Please upload a file smaller than 1MB.");
                break;

              case 507:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "사용 가능한 리소스 크기를 초과하였습니다. 관리자에게 문의해주세요." : "The available resource size has been exceeded. Please contact the administrator.");
                break;

              default:
                commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                break;
            }

            load();
          });
      }
    };

    const copy = (fileName) => {
      commLib.copy(`/files/${props.page}/${fileName}`);
    };

    const load = () => {
      state.files = [];

      httpLib.get(`/api/files/${props.page}`).then((res) => {
        if (Array.isArray(res.data)) {
          for (let f of res.data) {
            f.name = encodeURIComponent(f.name);
            state.files.push(f);
          }
        }
      });
    };

    const init = (force) => {
      if (state.init && !force) {
        return;
      }

      load();
      state.init = true;
    };

    return { store, state, init, defines, fileRef, attach, copy, zoom, remove };
  },
};
</script>
<style lang="scss" scoped>
$size: 140px;

.component-file {
  height: 100%;
  padding: 30px;
  font-size: 12px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  > .wrapper {
    text-align: center;
    margin: 0 -5px;

    .tile {
      display: inline-block;
      position: relative;
      vertical-align: top;
      border-radius: 0;
      border: 0;
      margin: 5px;
      height: $size;
      width: $size;
      background-position: center;
      background-size: cover;

      .body {
        padding: 35px 10px;
        text-align: center;
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: none;
        color: #fff;
        opacity: 0.5;
        transition: background 0.25s, opacity 0.25s;

        > .title {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }

        > .copy {
          cursor: pointer;
          display: inline-block;
          border: 1px solid #fff;
          padding: 6px 12px;
          font-size: 12px;
          margin-top: 12px;
          background: none;
          color: #fff;
          transition: background 0.25s;

          > i {
            margin-right: 5px;
          }

          &:hover {
            background: #fff;
            color: #2a2a2a;
          }
        }

        > i {
          position: absolute;
          cursor: pointer;
          bottom: 0;
          padding: 5px;

          &.expand {
            left: 0;
          }

          &.remove {
            right: 0;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, 0.76);
          opacity: 1;
        }
      }

      &:hover {
        .body {
          display: block;
        }
      }

      &.attach {
        > label {
          cursor: pointer;
          background: #fff;
          line-height: $size;
          font-size: 42px;
          transition: background 0.25s;
          text-align: center;
          cursor: pointer;
          width: 100%;
          height: 100%;

          &:hover {
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
