<template>
  <div className="component-loading"></div>
</template>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: rotate(360deg);
  }
}

.component-loading {
  display: inline-block;
  border: 3px solid #ddd;
  border-top: 3px solid #1259a7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s ease-in-out infinite;
}
</style>