<template v-if="state.init">
  <div class="component-component-box clear" :theme="theme" :class="{ activated: state.component }" :size="size" :position="position" :page="store.state.page.name ? 'opened' : 'closed'">
    <div class="search">
      <i class="fa fa-filter" />
      <input
        type="text"
        class="form-control"
        ref="filterRef"
        spellcheck="false"
        @input="type()"
        :placeholder="
          store.state.site.lang === 'ko'
            ? `${store.state.page.name ? '카테고리 필터 / 포커스하시려면 Alt + T 를 누르세요.' : '카테고리를 필터링하시려면 입력하세요.'}`
            : `${store.state.page.name ? 'Category filter / Press Alt + T to focus.' : 'Enter to filter the categories.'}`
        "
        v-model="state.filter"
      />
    </div>
    <div class="inner" v-if="categories.length === 1 ? categories[0].name !== 'all' : categories.length > 0">
      <div class="categories" ref="categoriesRef">
        <ul class="no-select">
          <li :class="{ active: state.category.name === c.name, 'background-point': theme === 'light' && state.category.name === c.name }" v-for="(c, idx) in categories" :key="idx" @click="setCategory(c)">
            <span>{{ c.name }}</span>
            <span class="count">{{ c.count }}</span>
          </li>
        </ul>
      </div>
      <div class="list" ref="listRef">
        <ul v-if="state.loaded">
          <li v-for="(c, idx) in state.components" :class="{ active: state.component === c }" :key="idx">
            <div class="preview" :style="[c.trim ? 'padding:0;' : '']" :title="title" @click="store.state.page.name ? upload(c.category, c.num) : download(c.category, c.num)">
              <img :src="c.imgUrl + '?thumb=true'" @click="setComponent(c)" />
            </div>
            <div class="bottom">
              <div class="title">
                <span class="name" :title="title" @click="store.state.page.name ? upload(c.category, c.num) : download(c.category, c.num)">{{ c.category + " " + c.num }}</span>
                <span class="tags">
                  <span class="badge" :class="['badge-' + badgets[t.charCodeAt(0) % badgets.length]]" v-for="(t, idx) in c.tags" :key="idx">{{ t }}</span>
                </span>
              </div>
            </div>
            <div class="icons">
              <i
                class="upload fa fa-upload"
                :title="sector === 'template' ? (store.state.site.lang === 'ko' ? '템플릿 적용' : 'Set template') : store.state.site.lang === 'ko' ? '루트에 추가' : 'Append to root'"
                @click="upload(c.category, c.num)"
                v-if="store.state.page.name"
              ></i>
              <i class="download fa fa-download" :title="store.state.site.lang === 'ko' ? '다운로드' : 'Download'" @click="download(c.category, c.num)" v-else></i>
              <i class="zoom fa fa-search" :title="store.state.site.lang === 'ko' ? '확대' : 'Zoom'" @click="zoom(idx, c.trim)"></i>
              <i class="code fa fa-code" @click="showCode(c)" :title="store.state.site.lang === 'ko' ? '코드 보기' : 'Show code'" v-if="sector === 'component'"></i>
              <i class="remove fa fa-trash" :title="store.state.site.lang === 'ko' ? '삭제' : 'Remove'" @click="remove(c.num)" v-if="custom"></i>
              <i class="info fa fa-info-circle" :title="store.state.site.lang === 'ko' ? '설명 보기' : 'Show description'" @click="read($event)"></i>
              <i class="unread" title="store.state.site.lang === 'ko' ? '설명 닫기' : 'Hide description'" @click="read($event)">&times;</i>
            </div>
            <div class="desc">
              <div class="inner">{{ c.desc[store.state.site.lang] }}</div>
            </div>
          </li>
        </ul>
        <div class="loading" v-else>
          <Loading />
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <div class="inner">
        <span v-if="custom">사용자 정의 </span>
        <span>{{ sector === "template" ? "템플릿이" : "컴포넌트가" }} 없습니다.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, reactive, ref } from "vue";
import { useStore } from "vuex";
import httpLib from "../libs/httpLib";
import commLib from "../libs/commonLib";
import Loading from "./Loading.vue";
// import $ from "jquery";

export default {
  components: { Loading },
  props: {
    callback: Function,
    change: Function,
    theme: String,
    sector: String,
    position: String,
    size: String,
    custom: Boolean,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      init: false,
      loaded: false,
      filter: "",
      category: {
        name: "",
        count: 0,
      },
      categories: [],
      component: null,
      components: [],
      command: null,
    });

    const badgets = ["primary", "success", "danger", "warning", "secondary", "info", "navy", "ocean", "yellowgreen", "purple", "dark"];
    const filterRef = ref(null);
    const inputRef = ref(null);
    const categoriesRef = ref(null);
    const listRef = ref(null);

    const title = computed(() => {
      if (store.state.page.name) {
        return store.state.site.lang === "ko" ? "클릭하시면 루트에 추가됩니다." : "Click to append root.";
      }

      return store.state.site.lang === "ko" ? "클릭하시면 소스가 다운로드 됩니다." : "Click to download source.";
    });

    const type = () => {
      if (state.filter && categories.value.length) {
        setCategory(categories.value[0]);
      } else {
        runChangeFunc();
      }
    };

    const categories = computed(() => {
      let count = 0;
      const result = [];

      if (state.filter) {
        for (let c of state.categories) {
          if (c.name.includes(state.filter)) {
            result.push(c);
          }
        }
      } else {
        result.push(...state.categories);

        state.categories.forEach((c) => {
          count += c.count;
        });

        result.unshift({ name: "all", count });
      }

      return result;
    });

    const showCode = (component) => {
      props.callback("open-modal", "code", "full", {
        component: component,
        custom: props.custom,
      });
    };

    const copy = () => {
      commLib.copy(inputRef.value.value);
      commLib.message.show("success", store.state.site.lang === "ko" ? "클립보드에 복사하였습니다." : "Copied to the clipboard.");
    };

    const focus = () => {
      nextTick(() => {
        filterRef.value.select();
      });
    };

    const read = (e) => {
      e.target.parentNode.parentNode.classList.toggle("read");
    };

    const upload = (category, num) => {
      const command = `@root ${props.sector === "template" ? "template" : "append"} ${category} ${num}`;
      store.state.refs.console.commandRef.runCommands(command);
    };

    const download = (category, num) => {
      location.href = `${store.state.site.url}/components/download/${category}/${num}`;
    };

    const zoom = (idx, trim) => {
      props.callback(
        "open-modal",
        "componentViewer",
        "full",
        {
          idx: idx,
          list: state.components,
          sector: props.sector,
        },
        trim
      );
    };

    const remove = (num) => {
      store.state.refs.console.commandRef.runCommands(`del ${props.sector} ${state.category.name} ${num}`);
    };

    const setComponent = (c) => {
      state.component = c;

      // nextTick(() => {
      //   const paddingTop = Number(
      //     window
      //       .getComputedStyle(listRef.value, null)
      //       .getPropertyValue("padding-top")
      //       .replace("px", "")
      //   );

      //   const scrollTop = listRef.value.scrollTop + listRef.value.querySelector("li.active").getBoundingClientRect().y - listRef.value.getBoundingClientRect().y - paddingTop;

      //   $(listRef.value)
      //     .stop()
      //     .animate({ scrollTop: scrollTop }, 250);
      // });
    };

    const setCategory = (c) => {
      if (state.category.name === c.name) {
        return;
      }

      state.category = c;
      state.component = null;
      setComponents();
    };

    const setCategories = (callback) => {
      httpLib
        .get(`/api/components/${props.sector}`, { custom: props.custom, mobile: store.state.toggle.mobile })
        .then((res) => {
          state.categories = res.data;

          if (typeof callback === "function") {
            callback();
          }

          runChangeFunc;
        })
        .catch(() => {
          commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
        });
    };

    const runChangeFunc = () => {
      nextTick(() => {
        if (typeof props.change === "function" && listRef.value) {
          Promise.all(
            Array.from(listRef.value.querySelectorAll("img"))
              .filter((img) => !img.complete)
              .map(
                (img) =>
                  new Promise((resolve) => {
                    img.onload = img.onerror = resolve;
                  })
              )
          ).then(() => {
            const categoryUl = categoriesRef.value.querySelector("ul");
            const ul = listRef.value.querySelector("ul");

            if (!categoryUl || !ul) {
              return;
            }

            const categoryHeight = categoryUl.offsetHeight + 2;
            const listHeight = ul.offsetHeight + 30;
            let height = categoryHeight;

            if (categoryHeight < listHeight) {
              height = listHeight;
            }

            props.change(height + 40);
          });
        }
      });
    };

    const setComponents = () => {
      state.components = [];
      state.loaded = false;

      httpLib
        .get(`/api/components/${props.sector}/${state.category.name}`, { custom: props.custom, mobile: store.state.toggle.mobile })
        .then((res) => {
          state.loaded = true;

          if (Array.isArray(res.data)) {
            const components = res.data;

            for (let c of components) {
              c.imgUrl = `${store.state.site.url}/components/preview/${props.sector}/${c.category}/${c.num}`;

              if (props.custom) {
                c.desc = {
                  ko: `사용자 정의 ${props.sector === "template" ? "템플릿" : "컴포넌트"}입니다.`,
                  en: `A custom ${props.sector}.`,
                };
              }
            }

            state.components = components;
          }

          runChangeFunc();
        })
        .catch(() => {
          commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
        });
    };

    const init = (force) => {
      if (state.init && !force) {
        return;
      }

      setCategories(() => {
        const categoryNames = state.categories.map((c) => c.name);

        if (categoryNames.length && (!state.category.name || !categoryNames.includes(state.category.name))) {
          state.category = state.categories[0];
        }

        if (state.category) {
          setComponents();
        }
      });

      state.init = true;
    };

    return {
      store,
      state,
      init,
      categories,
      badgets,
      filterRef,
      inputRef,
      categoriesRef,
      listRef,
      type,
      copy,
      read,
      zoom,
      showCode,
      upload,
      download,
      focus,
      title,
      setCategory,
      setCategories,
      setComponent,
      runChangeFunc,
      remove,
    };
  },
  // watch: {
  //   categories: {
  //     handler() {
  //     },
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.component-component-box {
  height: 100%;
  color: #fff;
  font-size: 14px;
  position: relative;
  padding-top: 40px;

  > .search {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    background: #262626;
    padding-left: 25px;

    i {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 25px;
      color: #545454;
      text-align: right;
      line-height: 40px;
    }

    input {
      height: 100%;
      font-size: 13px;
      border-radius: 0;
      background: none;
      border: 0;
      box-shadow: none;
      padding-left: 10px;
      outline: 0;
      color: #fff;
    }
  }

  > .inner {
    height: 100%;
    position: relative;

    > div {
      float: left;
      height: 100%;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &.categories {
        position: relative;
        overflow: auto;
        min-width: 100px;
        border-right: 1px solid #3c3c3c;
        z-index: 2;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            cursor: pointer;
            padding: 10px;
            border-bottom: 1px solid #3c3c3c;
            font-size: 13px;
            position: relative;

            .count {
              position: absolute;
              right: 9px;
              top: 11px;
              opacity: 0.25;
            }

            &:last-child {
              border-bottom: 0;
            }

            &.active {
              color: #0095ff;

              .count {
                opacity: 1;
              }
            }
          }
        }
      }

      &.list {
        position: absolute;
        padding: 10px 15px 15px 110px;
        width: 100%;
        left: 0;
        top: 0;

        > ul {
          list-style: none;
          margin: 0;
          padding: 0;
          column-count: 1;
          column-gap: 10px;

          > li {
            display: inline-block;
            width: 100%;
            margin: 5px 5px 10px 5px;
            background: #fff;
            border: 1px solid #3c3c3c;
            color: #333;
            vertical-align: top;
            position: relative;

            > .preview {
              padding: 10px;
              position: relative;
              font-size: 0;
              min-height: 34px;
              cursor: pointer;
              text-align: center;
            }

            > .bottom {
              padding: 10px 12.5px;
              background: #f7f7f7;
              border-top: 1px solid #ced4da;

              > .title {
                padding: 2.5px 0;

                .name {
                  cursor: pointer;
                }

                > .tags {
                  font-family: initial;
                  float: right;
                  padding: 2.5px 0;
                  text-align: right;
                  line-height: 15px;

                  > .badge {
                    border-radius: 0;
                    font-size: 10px;
                    margin-right: 1px;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }

                &:after {
                  content: " ";
                  display: table;
                  clear: right;
                }
              }
            }

            > .icons {
              display: none;
              position: absolute;
              top: 0;
              right: 0;
              text-align: right;
              z-index: 1;

              > i {
                background: rgba(0, 0, 0, 0.5);
                display: inline-block;
                color: #fff;
                cursor: pointer;
                vertical-align: middle;
                text-align: center;
                width: 34px;
                height: 34px;
                line-height: 34px;

                &.unread {
                  font-size: 25px;
                  font-style: normal;
                  line-height: 1.4;
                }

                &:hover {
                  background: rgba(0, 0, 0, 0.75);
                }
              }
            }

            > .desc {
              display: none;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.85);
              color: #fff;

              > .inner {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 15px;
              }
            }

            &:hover {
              > .icons {
                display: inline-block;
              }
            }

            &:not(.read) {
              > .icons > i.unread {
                display: none;
              }
            }

            &.read {
              > .icons {
                > i {
                  &.info {
                    display: none;
                  }

                  &.unread {
                    display: inline-block;
                  }

                  &:hover {
                    background: rgba(58, 58, 58, 0.75);
                  }
                }
              }

              > .desc {
                display: block;
              }
            }
          }
        }

        .loading {
          text-align: center;
          margin-top: 120px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  > .empty {
    display: table;
    height: 100%;
    width: 100%;

    > .inner {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }

  &.activated {
    > .inner {
      > div {
        &.center {
          width: 475px;
          border-right: 1px solid #3c3c3c;

          ul {
            li {
              opacity: 0.25;

              &.active {
                opacity: 1;
              }
            }
          }
        }

        &.right {
          padding-left: 475px;
          left: 0;
        }
      }
    }
  }

  &[position="side"] {
    > .inner {
      > div {
        &.center {
          > ul > li {
            width: 400px;
          }
        }
      }
    }

    &.activated {
      > .inner {
        > div {
          &.center {
            display: none;
          }

          &.right {
            padding-left: 100px;

            > .inner {
              > div {
                width: 100%;
                height: auto;
                float: none;

                &.side {
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &[size="xxl"] {
    > .inner > div.list > ul {
      column-count: 7;
    }
  }

  &[size="xl"] {
    > .inner > div.list > ul {
      column-count: 6;
    }
  }

  &[size="lg"] {
    > .inner > div.list > ul {
      column-count: 5;
    }
  }

  &[size="md"] {
    > .inner > div.list > ul {
      column-count: 4;
    }
  }

  &[size="sm"] {
    > .inner > div.list > ul {
      column-count: 3;
    }
  }

  &[size="xs"] {
    > .inner > div.list > ul {
      column-count: 2;
    }
  }

  &[theme="light"] {
    border: 1px solid #ddd;
    color: #000;
    background: #fff;

    > .search {
      background: none;
      border-bottom: 1px solid #ddd;

      input {
        color: inherit;
      }
    }

    > .inner > div {
      &.categories {
        border-color: #ddd;

        > ul > li {
          border-color: #ddd;

          .count {
            opacity: 0.7;
          }

          &.active {
            color: #fff;
          }
        }
      }

      &.list {
        > ul > li {
          border-color: #ddd;
        }
      }
    }
  }

  &[page="closed"] {
    &[size="xs"],
    &[size="xxs"] {
      > .inner > div.list > ul > li > .icons {
        display: none;
      }
    }

    &[size="xxl"],
    &[size="xl"],
    &[size="lg"],
    &[size="md"],
    &[size="sm"] {
      > .inner {
        > div {
          &.categories {
            min-width: 160px;

            ul {
              li {
                font-size: inherit;
              }
            }
          }

          &.list {
            padding-left: 170px;
          }
        }
      }
    }

    &[size="xxl"] {
      > .inner > div.list > ul {
        column-count: 6;
      }
    }

    &[size="xl"] {
      > .inner > div.list > ul {
        column-count: 5;
      }
    }

    &[size="lg"] {
      > .inner > div.list > ul {
        column-count: 4;
      }
    }

    &[size="md"] {
      > .inner > div.list > ul {
        column-count: 3;
      }
    }

    &[size="sm"] {
      > .inner > div.list > ul {
        column-count: 2;
      }
    }

    &[size="xs"] {
      > .inner > div.list > ul {
        column-count: 1;
      }
    }
  }
}
</style>
