import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import { router } from "./router";
import "bootstrap/dist/css/bootstrap.min.css";

if (process.env.NODE_ENV === "production") {
  store.state.site.host = "publessing.africalib.org";
  store.state.site.url = "https://server.publessing.africalib.org";
}

if (location.pathname && location.pathname.length > 1) {
  const arr = location.pathname.substring(1).split("/");
  const lang = arr[0] === "ko" ? "ko" : "en";
  store.state.site.lang = lang;
}

createApp(App)
  .use(store)
  .use(router)
  .mount("#app");
