<template>
  <div class="component-comment">
    <div class="list">
      <template v-if="state.comment.count">
        <ul>
          <li v-for="(c1, idx1) in state.comment.list" :key="idx1">
            <div class="symbol font-paint">{{ c1.writer === "unknown" ? "?" : c1.writer[0] }}</div>
            <div class="header">
              <div class="writer">{{ c1.writer }}</div>
              <div class="actions">
                <button
                  class="reply"
                  :title="store.state.site.lang === 'ko' ? '답장' : 'Reply'"
                  @click="reply(c1.id, $event)"
                  v-if="store.state.account.login"
                  :disabled="!state.comment.loaded || !state.form.standalone.loaded || !state.form.dependency.loaded"
                >
                  <i class="fa fa-reply"></i>
                </button>
                <button
                  class="remove"
                  :title="store.state.site.lang === 'ko' ? '삭제' : 'Remove'"
                  @click="remove(c1.id)"
                  v-if="c1.mid === store.state.account.mid"
                  :disabled="!state.comment.loaded || !state.form.standalone.loaded || !state.form.dependency.loaded"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
              <span class="date" :title="c1.insDate">{{ c1.ago }}</span>
            </div>
            <div class="message">{{ c1.message }}</div>
            <ul v-if="c1.replies && c1.replies.length">
              <li v-for="(c2, idx2) in c1.replies" :key="idx2">
                <div class="symbol font-paint">{{ c2.writer === "unknown" ? "?" : c2.writer[0] }}</div>
                <div class="header">
                  <div class="writer">{{ c2.writer }}</div>
                  <div class="actions">
                    <button class="remove" @click="remove(c2.id)" v-if="c2.mid === store.state.account.mid">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                  <span class="date" :title="c2.insDate">{{ c2.ago }}</span>
                </div>
                <div class="message">{{ c2.message }}</div>
              </li>
            </ul>
            <div class="write form" v-if="state.form.dependency.parentId === c1.id">
              <div class="inner clearfix">
                <div class="text left">
                  <textarea
                    class="form-control"
                    :placeholder="store.state.site.lang === 'ko' ? '내용을 입력하시고 저장 버튼을 눌러주세요.' : 'Enter the message and click the Save button.'"
                    :disabled="!state.comment.loaded || !state.form.standalone.loaded || !state.form.dependency.loaded"
                    v-model="state.form.dependency.message"
                  ></textarea>
                </div>
                <div class="text right">
                  <button class="submit btn btn-point-line" :disabled="!state.comment.loaded || !state.form.standalone.loaded || !state.form.dependency.loaded" @click="submit(state.form.dependency)">
                    {{ state.form.dependency.loaded ? (store.state.site.lang === "ko" ? "저장" : "Save") : store.state.site.lang === "ko" ? "저장 중..." : "Saving..." }}
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="more" v-if="state.comment.count && state.comment.list.length < state.comment.count">
          <button @click="expand()" :disabled="!state.comment.loaded">{{ state.comment.loaded ? "더 보기" : "불러오는 중..." }}</button>
        </div>
      </template>
    </div>
    <div class="write form">
      <div class="inner clearfix">
        <div class="text left">
          <textarea
            class="form-control"
            :placeholder="
              store.state.account.login
                ? store.state.site.lang === 'ko'
                  ? '내용을 입력하시고 저장 버튼을 눌러주세요.'
                  : 'Enter the message and click the Save button.'
                : store.state.site.lang === 'ko'
                ? '로그인 후 입력해주세요.'
                : 'Login is required.'
            "
            :disabled="!store.state.account.login || !state.comment.loaded || !state.form.standalone.loaded || !state.form.dependency.loaded"
            v-model="state.form.standalone.message"
            ref="textRef"
          ></textarea>
        </div>
        <div class="text right">
          <button class="submit btn btn-point" :disabled="!store.state.account.login || !state.comment.loaded || !state.form.standalone.loaded || !state.form.dependency.loaded" @click="submit(state.form.standalone)">
            {{ state.form.standalone.loaded ? (store.state.site.lang === "ko" ? "저장" : "Save") : store.state.site.lang === "ko" ? "저장 중..." : "Saving..." }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { nextTick, onMounted } from "@vue/runtime-core";
import commLib from "../libs/commonLib";
import httpLib from "../libs/httpLib";
import { useStore } from "vuex";

export default {
  props: {},
  setup() {
    const store = useStore();
    const state = reactive({
      form: {
        standalone: {
          message: "",
          parentId: null,
          loaded: true,
        },
        dependency: {
          message: "",
          parentId: null,
          loaded: true,
        },
      },
      comment: {
        page: 1,
        loaded: true,
        count: 0,
        list: [],
      },
    });

    const textRef = ref();

    const expand = () => {
      state.comment.page += 1;
      set();
    };

    const submit = (form) => {
      if (!form.message) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "내용을 입력해주세요." : "Please enter your message.");
        textRef.value.focus();
        return;
      } else if (!state.comment.loaded || !form.loaded) {
        return;
      }

      form.loaded = false;

      const args = {
        message: form.message,
        parentId: form.parentId,
      };

      httpLib
        .post("/api/comments/submit", args)
        .then(() => {
          form.loaded = true;
          form.message = "";
          form.parentId = "";

          commLib.message.show("success", store.state.site.lang === "ko" ? "코멘트를 저장하였습니다." : "Saved your comment.");
          set(true);
        })
        .catch(() => {
          form.loaded = true;
          commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
        });
    };

    const reply = (id, e) => {
      if (state.form.dependency.parentId === id) {
        state.form.dependency = {
          message: null,
          parentId: null,
          loaded: true,
        };

        return;
      }

      state.form.dependency.parentId = id;

      nextTick(() => {
        e.target
          .closest("li")
          ?.querySelector("textarea")
          ?.focus();
      });
    };

    const remove = (id) => {
      if (confirm(store.state.site.lang === "ko" ? "선택하신 코멘트를 삭제하시겠습니까?" : "Are you sure you want to delete the selected comment?")) {
        state.comment.loaded = false;

        httpLib
          .delete("/api/comments/" + id)
          .then(() => {
            state.comment.loaded = true;
            commLib.message.show("success", store.state.site.lang === "ko" ? "코멘트를 삭제하였습니다." : " The comment has been deleted.");
            set(true);
          })
          .catch(() => {
            commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
          });
      }
    };

    const set = (init) => {
      if (init) {
        state.comment.page = 1;
        state.comment.list = [];
      }

      const args = {
        message: state.form.standalone.message,
        page: state.comment.page,
      };

      state.comment.loaded = false;

      httpLib
        .get("/api/comments", args)
        .then((res) => {
          state.comment.loaded = true;

          if (res.data && Array.isArray(res.data.list)) {
            state.comment.count = res.data.count;
            state.comment.list.push(...res.data.list);
          }
        })
        .catch(() => {
          state.comment.loaded = true;
          commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
        });
    };

    onMounted(() => {
      set(true);
    });

    return { store, state, textRef, set, reply, remove, expand, submit };
  },
};
</script>
<style lang="scss" scoped>
.component-comment {
  .form {
    padding-right: 0;

    .inner {
      height: 106.5px !important;
      margin: 0 -15px;

      > div {
        padding-right: 0;
        float: left;
        padding: 0 15px;
        height: 100%;

        textarea {
          border-radius: 0;
          font-size: 14px;
          padding: 12.5px;
          resize: none;
          height: 100%;

          &:focus {
            outline: 0;
            box-shadow: none;
            border-color: #146ece;
          }
        }

        &.left {
          width: 85%;
          padding-right: 0;
        }

        &.right {
          width: 15%;
        }
      }

      button {
        border-radius: 0;
        padding: 0;
        width: 100%;
        font-size: 13px;
        height: 100%;
        line-height: 106.5px;
      }
    }
  }

  > div {
    &.list {
      margin-bottom: 25px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
          background: #fff;
          position: relative;
          margin-bottom: 15px;
          border: 1px solid #ddd;
          padding: 30px 25px 25px 92.5px;

          .symbol {
            text-transform: uppercase;
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 21px;
            font-weight: bold;
            color: #1259a7;
            text-align: center;
            position: absolute;
            left: 15px;
            top: 25px;
            border: 1px solid #dedede;
          }

          > .header {
            margin-bottom: 7.5px;
            font-size: 14px;
            position: relative;
            padding-right: 100px;

            .writer {
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }

            .actions {
              position: absolute;
              right: 0;
              top: 30px;

              button {
                cursor: pointer;
                padding: 0;
                border: 0;
                color: #3a3a3a;
                margin-left: 15px;
              }
            }

            .date {
              position: absolute;
              display: inline-block;
              top: 0;
              right: 0;
              margin-left: 10px;
              text-align: right;
              max-width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .message {
            font-size: 14px;
            color: #262626;
            min-height: 21px;
            padding-right: 15px;
            white-space: pre-line;
          }

          .form {
            border-top: 1px solid #ddd;
            padding-top: 25px;
            margin-top: 25px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          ul {
            padding-top: 15px;

            li {
              margin-top: 15px;
            }
          }
        }
      }

      .more {
        button {
          border-radius: 0;
          padding: 0;
          width: 100%;
          font-size: 13px;
          margin-top: 10px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  &[size="xs"],
  &[size="xxs"] {
    .form {
      .row {
        height: 100px !important;
      }

      .inner {
        height: auto !important;

        > div {
          float: none;

          &.left {
            width: 100%;
            padding-right: 15px;
          }

          &.right {
            width: 100%;
            padding-top: 15px;

            button {
              height: 50px;
              line-height: 50px;
            }
          }
        }
      }
    }
  }

  &[size="xxs"] {
    > div.list ul > li {
      padding: 20px;

      .symbol {
        display: none;
      }
    }
  }
}
</style>
