<template>
  <div class="modal-password">
    <div class="pt-4 pb-4 text-center">
      <h1 class="font-paint color-point">Publessing</h1>
      <h3 class="font-coding">{{ store.state.site.lang === "ko" ? "패스워드 변경" : "Change password" }}</h3>
    </div>
    <div class="form mt-2">
      <div class="mb-4">
        <label for="currPassword">{{ store.state.site.lang === "ko" ? "현재 패스워드" : "Current password" }}</label>
        <input type="password" class="form-control" id="currPassword" v-model="state.currPassword" @keypress.enter="change()" />
      </div>
      <div class="mb-4">
        <label for="newPassword">{{ store.state.site.lang === "ko" ? "새로운 패스워드" : "New password" }}</label>
        <input type="password" class="form-control" id="newPassword" v-model="state.newPassword" @keypress.enter="change()" />
      </div>
      <div class="mb-4">
        <label for="confirmPassword">{{ store.state.site.lang === "ko" ? "새로운 패스워드 재입력" : "Repeat new password" }}</label>
        <input type="password" class="form-control" id="confirmPassword" v-model="state.confirmPassword" @keypress.enter="change()" />
      </div>
      <button class="btnbtn-lg btn-block btn-point no-radius" type="button" @click="change()" :disabled="!state.loaded">
        {{ `${state.loaded ? (store.state.site.lang === "ko" ? "업데이트" : "Update") : store.state.site.lang === "ko" ? "업데이트 하는 중..." : "Updating..."}` }}
      </button>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import httpLib from "../libs/httpLib";
import commLib from "../libs/commonLib";
import { useStore } from "vuex";

export default {
  props: {
    callback: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      loaded: true,
      currPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    const change = () => {
      if (!state.currPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "현재 패스워드를 입력해주세요." : "Current password is required.");
        document.getElementById("currPassword").focus();
      } else if (!state.newPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "새로운 패스워드를 입력해주세요." : "New password is required.");
        document.getElementById("newPassword").focus();
      } else if (!state.confirmPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드를 한 번 더 입력해주세요." : "Please repeat new password.");
        document.getElementById("confirmPassword").focus();
      } else if (state.currPassword === state.newPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "현재 패스워드와 새로운 패스워드가 동일합니다." : "The current password and the new password are the same.");
        document.getElementById("confirmPassword").focus();
      } else if (state.newPassword !== state.confirmPassword) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "새로운 패스워드를 동일하게 입력해주세요." : "Please enter repeat password correctly.");
        document.getElementById("confirmPassword").focus();
      } else if (state.newPassword.length < 8 || state.newPassword.length > 21) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "패스워드는 8자리 이상 21자리 이하로 입력해주세요." : "Please enter a password between 8 and 21 digits.");
        document.getElementById("newPassword").focus();
      } else {
        const args = {
          currPassword: state.currPassword,
          newPassword: state.newPassword,
        };

        state.loaded = false;

        httpLib
          .post("/api/account/password", args)
          .then(() => {
            state.loaded = true;
            commLib.message.show("success", store.state.site.lang === "ko" ? "패스워드를 성공적으로 변경하였습니다." : "Your password has been changed successfully.");
            props.callback("close-modal");
          })
          .catch((err) => {
            state.loaded = true;
            switch (err.response?.status) {
              case 404:
                commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 현재 패스워드가 올바르지 않습니다." : "The current password is incorrect");
                break;

              default:
                commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                break;
            }
          });
      }
    };

    onMounted(() => {
      setTimeout(() => {
        document.getElementById("currPassword").focus();
      }, 250);
    });

    console.log(props);

    return {
      store,
      state,
      change,
    };
  },
};
</script>
<style lang="scss" scoped>
.modal-password {
  padding: 25px;

  h1 {
    font-weight: bold;
    font-size: 35px;
  }

  h3 {
    font-size: 25px;
    margin-top: 35px;
  }

  input:not([type="checkbox"]) {
    height: 50px;
    border-radius: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: #146ece;
    }
  }

  label {
    font-size: 14px;
  }

  .labels {
    label {
      cursor: pointer;

      input[type="checkbox"] {
        margin-top: 0.4rem;
      }

      &.reset:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    font-size: 14px;
    height: 50px;

    &[disabled] {
      opacity: 0.5;
    }
  }
}
</style>
