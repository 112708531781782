<template>
  <div class="modal-component-viewer" :style="[state.slide.trim ? 'padding:0' : '']">
    <div class="preview">
      <img :src="state.slide.url" @load="setStyle($event)" />
    </div>
    <div class="angle left" title="Slide to prev" :style="state.angleStyle" @click="move('prev')">
      <img src="/assets/img/ico.angle.left.white.png" alt="Slide to prev" />
    </div>
    <div class="angle right" title="Slide to next" :style="state.angleStyle">
      <img src="/assets/img/ico.angle.right.white.png" @click="move('next')" alt="Slide to next" />
    </div>
    <div class="top">
      <span class="name">{{ state.slide.category }} {{ state.slide.num }}</span>
      <span class="desc">{{ state.slide.desc[store.state.site.lang] }}</span>
    </div>
    <div class="action" :title="data.sector === 'template' ? (store.state.site.lang === 'ko' ? '템플릿 적용' : 'Set template') : store.state.site.lang === 'ko' ? '루트에 추가' : 'Append to root'" v-if="store.state.page.name">
      <i class="fa fa-upload" @click="run()"></i>
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  props: {
    data: Object,
    callback: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      slide: {
        idx: 0,
        url: "",
        category: "",
        num: 0,
        desc: "",
        list: [],
        trim: false,
      },
      angleStyle: {
        display: "none",
        top: 0,
      },
    });

    const setStyle = (e) => {
      state.angleStyle.display = "block";
      state.angleStyle.top = e.target.parentNode.parentNode.offsetHeight / 2 + "px";
    };

    const run = () => {
      const command = `@root ${props.data.sector === "template" ? "template" : "append"} ${state.slide.category} ${state.slide.num}`;
      store.state.refs.console.commandRef.runCommands(command);
      props.callback("close-modal");
    };

    const move = (direction) => {
      if (direction === "next" && state.slide.idx < state.slide.list.length - 1) {
        state.slide.idx += 1;
      } else if (direction === "prev" && state.slide.idx > 0) {
        state.slide.idx -= 1;
      } else {
        return;
      }

      state.slide.url = state.slide.list[state.slide.idx].imgUrl;
      state.slide.num = state.slide.list[state.slide.idx].num;
      state.slide.desc = state.slide.list[state.slide.idx].desc[store.state.site.lang];
      state.slide.trim = state.slide.list[state.slide.idx].trim;
      state.slide.category = state.slide.list[state.slide.idx].category;
    };

    onMounted(() => {
      const idx = props.data.idx;
      const list = props.data.list;
      state.slide.idx = idx;
      state.slide.url = list[idx].imgUrl;
      state.slide.num = list[idx].num;
      state.slide.desc = list[idx].desc[store.state.site.lang];
      state.slide.trim = list[idx].trim;
      state.slide.category = list[idx].category;
      state.slide.list = list;
    });

    return { store, state, run, move, setStyle };
  },
};
</script>
<style lang="scss" scoped>
.modal-component-viewer {
  border: 1px solid #3c3c3c;
  padding: 25px;

  .preview {
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .angle {
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -33.5px;
    display: none;

    &.left {
      left: -75px;
    }

    &.right {
      right: -75px;
    }
  }

  .top {
    position: absolute;
    top: -60px;
    color: #fff;
    width: 100%;
    left: 0;
    font-size: 20px;
    padding: 15px 100px 15px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .name {
      font-size: 20px;
      display: inline-block;
      margin-right: 15px;
      font-weight: bold;
    }

    .desc {
      font-size: 14px;
    }
  }

  .action {
    position: absolute;
    top: -50px;
    right: 50px;

    i {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.21);
      font-size: 21px;
      text-align: center;
      margin-right: 1px;
      line-height: 50px;
      color: #fff;
      width: 50px;
    }
  }

  @media (max-width: 767px) {
    .angle {
      display: none !important;
    }
  }
}
</style>
