<template>
  <div class="component-home" :size="state.size" :browser="browser" :console="visible ? 'visible' : 'hidden'">
    <nav>
      <div class="wrapper">
        <router-link :to="'/' + store.state.site.lang" class="brand font-paint">P</router-link>
        <ul>
          <li v-for="(n, idx) in state.navs" :class="{ active: state.nav === n.name }" :key="idx">
            <router-link :to="`/${store.state.site.lang}/${n.name}`">{{ n.title[store.state.site.lang] }}</router-link>
          </li>
        </ul>
        <div class="toggles">
          <span class="toggle console">
            <div :title="state.lang === 'ko' ? '콘솔' : 'Console'" @click="callback('toggle-console')">
              <span
                class="circle fa fa-window-maximize"
                :style="{
                  left: visible ? '' : 'calc(100% - 32px)',
                }"
              />
              <span class="text" :style="{ right: visible ? '' : 'calc(100% - 24px)' }">{{ visible ? "on" : "off" }}</span>
            </div>
            <img src="/assets/img/logo.en.svg" class="hide" />
          </span>
          <span class="toggle lang">
            <div :title="state.lang === 'ko' ? '언어(Lnaguage)' : 'Language(언어)'" @click="setLang()">
              <span
                class="circle"
                :style="{
                  backgroundColor: state.lang === 'ko' ? '#fff' : '#012169',
                  backgroundImage: state.lang === 'ko' ? 'url(/assets/img/logo.ko.svg)' : 'url(/assets/img/logo.en.svg)',
                  left: state.lang === 'ko' ? '' : 'calc(100% - 32px)',
                }"
              />
              <span class="text" :style="{ right: state.lang === 'ko' ? '' : 'calc(100% - 24px)' }">{{ state.lang }}</span>
            </div>
            <img src="/assets/img/logo.en.svg" class="hide" />
          </span>
        </div>
      </div>
    </nav>
    <article>
      <div class="wrapper" ref="articleWrapperRef">
        <router-view :visible="visible" :size="state.size" :callback="callback" />
      </div>
    </article>
    <footer>
      <div class="wrapper clearfix">
        <div class="copyright">&copy; 2021. Publessing. All rights reserved.</div>
        <div class="langs">
          <span class="lang ko" title="한국어" @click="setLang('ko')" :style="{ backgroundImage: 'url(/assets/img/logo.ko.svg)' }"></span>
          <span class="lang en" title="English" @click="setLang('en')" :style="{ backgroundImage: 'url(/assets/img/logo.en.svg)' }"></span>
        </div>
        <router-link :to="`/${store.state.site.lang}/community`" class="email" @click="climb()">africalibrary21@gmail.com</router-link>
      </div>
    </footer>
    <button class="climb btn color-point border-point" ref="climbBtnRef" @click="climb()">TOP</button>
    <div class="fixed-message" v-if="state.visible.message">
      <div class="inner">
        <div>{{ store.state.site.lang === "ko" ? env.VUE_APP_ALERT_KO : env.VUE_APP_ALERT_EN }}</div>
        <span class="close-btn" @click="state.visible.message = false">&times;</span>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import commLib from "../libs/commonLib";
import defines from "../defines.json";
import $ from "jquery";
import { useStore } from "vuex";

export default {
  props: {
    visible: Boolean,
    callback: Function,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      size: "",
      nav: "",
      navs: [],
      lang: store.state.site.lang,
      visible: {
        message: true,
      },
    });

    let timer = null;
    const articleWrapperRef = ref(null);
    const climbBtnRef = ref(null);
    const browser = commLib.getBrowser();

    const setLang = (lang) => {
      if (!lang) {
        lang = store.state.site.lang === "ko" ? "en" : "ko";
      }

      state.lang = lang;

      setTimeout(() => {
        localStorage.setItem("lang", lang);
        location.replace(location.pathname.replace(store.state.site.lang, lang));
      }, 250);
    };

    const climb = () => {
      $("html,body")
        .stop()
        .animate({
          scrollTop: 0,
        });
    };

    const check = () => {
      if (window.scrollY > 300) {
        $(climbBtnRef.value).fadeIn();
      } else {
        $(climbBtnRef.value).fadeOut();
      }
    };

    const setSize = () => {
      if (!articleWrapperRef.value) {
        return;
      }

      const width = articleWrapperRef.value.offsetWidth;

      if (width > 1920) {
        state.size = "xxl";
      } else if (width >= 1920) {
        state.size = "xl";
      } else if (width >= 1600) {
        state.size = "lg";
      } else if (width >= 1140) {
        state.size = "md";
      } else if (width >= 960) {
        state.size = "sm";
      } else if (width >= 640) {
        state.size = "xs";
      } else {
        state.size = "xxs";
      }
    };

    const activate = () => {
      state.nav = "";

      if (location.pathname && location.pathname.length > 1) {
        const arr = location.pathname.split("/");

        if (arr.length > 2) {
          const nav = state.navs.find((n) => n.name === arr[2]);

          if (nav) {
            state.nav = nav.name;
            document.title = nav.title[store.state.site.lang] + " - " + (store.state.site.lang === "ko" ? process.env.VUE_APP_NAME_KO : process.env.VUE_APP_NAME_EN);
            return;
          }
        }
      }

      document.title = store.state.site.lang === "ko" ? process.env.VUE_APP_TITLE_KO : process.env.VUE_APP_TITLE_EN;
    };

    for (let n of defines.navs) {
      state.navs.push(n);
    }

    window.onscroll = () => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        check();
      });
    };

    onMounted(() => {
      activate();
      check();

      new ResizeObserver(setSize).observe(articleWrapperRef.value);
    });

    return { store, state, setLang, articleWrapperRef, climbBtnRef, browser, climb, activate, defines, env: process.env };
  },
  watch: {
    $route() {
      $(window).scrollTop(0);
      this.activate();
    },
  },
};
</script>
<style lang="scss">
.component-home {
  position: relative;

  .video {
    border: 1px solid #ddd;
  }

  .wrapper {
    width: 1140px;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 15px;
  }

  > nav {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #ddd;
    background: #f7f7f7;
    transition: background-color 0.25s;
    z-index: 3;

    > .wrapper {
      position: relative;
      padding-left: 85px;
      padding-right: 170px;

      .brand {
        position: absolute;
        color: #3c3c3c;
        top: 0;
        left: 15px;
        font-size: 25px;
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        height: 100%;
        width: 60px;
        text-align: center;
        line-height: 57px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        li {
          display: inline-block;
          margin: 0 5px;

          > a {
            color: inherit;
            padding: 19px 10px;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            text-decoration: none;
          }

          &.active {
            font-weight: bold;
          }

          &:first-child {
            > a {
              letter-spacing: -0.767px;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .toggles {
        position: absolute;
        top: 6.5px;
        right: 15px;

        .toggle {
          padding-top: 13px;
          padding-left: 13px;
          display: inline-block;
          vertical-align: top;

          > div {
            display: inline-block;
            width: 60px;
            height: 24px;
            border-radius: 16px;
            cursor: pointer;
            background: #5a5a5a;
            position: relative;
            transition: background-color 0.25s;

            .circle {
              display: inline-block;
              background-size: 100%;
              background-position: center;
              background-color: #fff;
              background-repeat: no-repeat;
              border-radius: 50%;
              border: 1px solid #5a5a5a;
              width: 32px;
              height: 32px;
              position: absolute;
              top: -4px;
              left: 0;
              transition: background-color 0.25s, background-image 0.25s, left 0.25s;
            }

            .text {
              color: #fff;
              font-size: 12px;
              position: absolute;
              right: 9px;
              top: 2px;
              transition: right 0.25s;
            }
          }

          &.console {
            div {
              .circle {
                text-align: center;
                line-height: 30px;
              }
            }
          }
        }

        &.white {
          background: #fff;
        }
      }
    }
  }

  > article {
    .content {
      .image {
        height: 250px;
        display: block;
        margin: 50px auto 10px auto;
      }

      .part {
        padding-bottom: 25px;

        .sm-title {
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 15px;
          margin-top: 25px;
          border-bottom: 1px solid #ccc;
          margin-bottom: 15px;
        }
      }

      &.full {
        width: 100%;
        padding: 0 15px;
      }
    }
  }

  > footer {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 35px 0;
    background: #f7f7f7;

    span {
      display: inline-block;
    }

    .langs {
      margin: 3px 0 0 5px;
      float: right;

      .lang {
        cursor: pointer;
        margin-left: 5px;
        height: 20px;
        width: 30px;
        border: 1px solid #ddd;
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;

        &.ko {
          background-color: #fff;
        }

        &.en {
          background-color: #012169;
        }
      }
    }

    .copyright {
      float: left;
    }

    .email {
      color: inherit;
      float: right;
    }
  }

  > .climb {
    position: fixed;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 0;
    right: 15px;
    bottom: 15px;
    padding: 0;
    width: 66px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    border: 1px solid;
    box-shadow: none;
    display: none;
    z-index: 10;

    &.on {
      opacity: 1;
    }
  }

  .fixed-message {
    display: none;
    position: fixed;
    bottom: 42px;
    left: 0;
    z-index: 20;
    width: 100%;
    padding: 15px;

    > .inner {
      background: rgba(0, 0, 0, 0.85);
      border: 1px solid #aaa;
      color: #fff;
      padding: 15px 35px 15px 15px;
      position: relative;

      .close-btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 10px;
        font-size: 27px;
        cursor: pointer;
        line-height: 50px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &[size="xs"],
  &[size="xxs"] {
    > nav > .wrapper {
      padding-left: 70px;
      padding-right: 0;

      ul {
        li {
          margin: 0;
        }
      }

      .brand {
        left: 0;
        border-left: 0;
      }
    }
  }

  &[size="xxs"] {
    > footer {
      text-align: center;

      .copyright {
        float: none;
        margin-bottom: 5px;
      }

      .email {
        float: none;
      }

      > .wrapper {
        .langs {
          margin: 15px 0 7px 0;
          float: right;
          float: none;
        }
      }
    }
  }

  @media (max-width: 1139px) {
    > nav > .wrapper .toggles {
      display: none;
    }

    .fixed-message {
      display: block;
    }
  }
}
</style>
