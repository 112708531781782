<template>
  <div class="component-ground" :page="store.state.page.name ? 'opened' : ''">
    <div class="ready" :style="state.style" v-if="state.visible.ground">
      <Webview ref="webviewRef" :page="store.state.page.name" :postback="postback" v-if="store.state.page.name" />
      <Home :login="store.state.account.login" :device="device" :callback="callback" :visible="state.visible.console" v-else />
      <Console :callback="callback" ref="consoleRef" :console="state.visible.console ? 'visible' : 'hidden'" v-if="state.visible.console" />
      <div class="modals" :class="state.modal.size" v-if="state.modal.name" @click="closeModal($event)">
        <div class="wrapper">
          <div class="inner">
            <Join :callback="callback" v-if="state.modal.name === 'join'" />
            <Login :callback="callback" v-else-if="state.modal.name === 'login'" />
            <Password :callback="callback" v-else-if="state.modal.name === 'password'" />
            <Comment v-else-if="state.modal.name === 'comment'" />
            <ComponentViewer :callback="callback" :data="state.modals.componentViewer" v-else-if="state.modal.name === 'componentViewer'" ref="componentViewerRef" />
            <Code :data="state.modals.code" v-else-if="state.modal.name === 'code'" />
            <ImageViewer :data="state.modals.imageViewer" v-else-if="state.modal.name === 'imageViewer'" ref="imageViewerRef" />
            <VideoViewer :data="state.modals.videoViewer" v-else-if="state.modal.name === 'videoViewer'" ref="videoViewerRef" />
          </div>
          <span class="close-btn" :title="store.state.site.lang === 'ko' ? '모달 닫기' : 'Close modal'">&times;</span>
        </div>
      </div>
    </div>
    <div class="error" v-else-if="state.visible.error">
      <img src="/assets/img/character.error.server.png" />
      <div class="text">
        <b>문의하기</b>
        <div>africalibrary21@gmail.com</div>
      </div>
    </div>
    <div class="loading" v-else-if="!state.visible.ground">
      <Loading />
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { nextTick } from "@vue/runtime-core";
import { useStore } from "vuex";
import Console from "./Console";
import Webview from "./Webview";
import Home from "./Home";
import Loading from "./Loading";
import Join from "../modals/Join";
import Login from "../modals/Login";
import Password from "../modals/Password";
import Comment from "../modals/Comment";
import ComponentViewer from "../modals/ComponentViewer";
import Code from "../modals/Code";
import ImageViewer from "../modals/ImageViewer";
import VideoViewer from "../modals/VideoViewer";
import commLib from "../libs/commonLib";
import httpLib from "../libs/httpLib";

export default {
  components: { Console, Webview, Join, Login, Password, Comment, ComponentViewer, ImageViewer, VideoViewer, Code, Home, Loading },
  setup() {
    const store = useStore();
    const state = reactive({
      visible: {
        ground: false,
        console: false,
        error: false,
      },
      style: {
        paddingBottom: 0,
        paddingLeft: 0,
      },
      modal: {
        name: "",
        size: "normal",
      },
      modals: {
        code: {
          component: {},
          custom: false,
        },
        imageViewer: {
          idx: 0,
          list: [],
          page: null,
        },
        componentViewer: {
          idx: 0,
          list: [],
          sector: null,
        },
        videoViewer: {
          value: null,
        },
      },
    });

    const consoleRef = ref(null);
    const webviewRef = ref(null);
    const componentViewerRef = ref(null);
    const imageViewerRef = ref(null);
    const videoViewerRef = ref(null);

    const consoleVisible = commLib.cookie.get("consoleVisible");
    const webviewTag = commLib.cookie.get("webviewTag");
    const mobileTag = commLib.cookie.get("mobileTag");

    const device = /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "mobile" : "desktop";

    const clearAssistList = () => {
      if (consoleRef.value && consoleRef.value.commandRef) {
        consoleRef.value.commandRef.state.assist.list = [];
        consoleRef.value.commandRef.state.assist.selectedIdx = null;
      }
    };

    const closeModal = (e) => {
      if (!state.modal.name) {
        return;
      }

      if (!e || e.target.classList.contains("close-btn")) {
        state.modal.name = "";

        nextTick(() => {
          consoleRef.value?.commandRef.textareaRef.focus();
        });
      }
    };

    const renewResource = () => {
      httpLib.get("/api/account/resource").then((res) => {
        if (commLib.isNumeric(res.data)) {
          store.state.account.resource = res.data;
        }
      });
    };

    const setComponentCategories = () => {
      httpLib.get(`/api/components/categories`).then((res) => {
        if (Array.isArray(res.data)) {
          store.commit("setComponents", res.data);
        }
      });
    };

    const setPages = () => {
      httpLib.get(`/api/pages`).then((res) => {
        if (Array.isArray(res.data)) {
          store.commit("setPages", res.data);
        }
      });
    };

    const setAccount = (func1, func2) => {
      store.state.account.mid = 0;
      store.state.account.email = "";
      store.state.account.resource = 0;
      store.state.account.login = false;

      httpLib
        .get("/api/account/base")
        .then((res) => {
          if (typeof func1 === "function") {
            func1();
          }

          if (!res.data || !Object.keys(res.data).length) {
            return;
          }

          store.state.account.mid = Number(res.data.mid);
          store.state.account.email = res.data.email;
          store.state.account.resource = res.data.resource;
          store.state.account.login = true;

          setPages();
          setComponentCategories();

          if (process.env.NODE_ENV === "production") {
            store.state.site.url = "https://server.publessing.africalib.org";
          }
        })
        .catch(() => {
          if (typeof func2 === "function") {
            func2();
          }
        });
    };

    const go = (hash) => {
      if (hash) {
        location.hash = `/${hash}`;
      }
      // else {
      // location.hash = "";
      // history.replaceState(null, null, " ");
      // }
    };

    const watch = () => {
      const hash = location.hash;
      const hashArr = hash.split("/");
      const pageName = hashArr[1];
      const error = () => {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "입력하신 이름의 페이지가 존재하지 않습니다." : "The page does not exist.");
      };

      store.commit("setPage", {
        ...store.state.page,
        name: null,
      });

      if (!hash || hashArr.length < 2) {
        return;
      } else if (!pageName) {
        return;
      }

      httpLib
        .get(`/api/pages/details/${pageName}`)
        .then((res) => {
          const page = res.data;

          if (page && typeof page === "object" && Object.keys(page).length) {
            const seq = page.sequence;
            state.visible.console = true;

            store.commit("setPage", {
              ...store.state.page,
              seq: seq,
              maxSeq: seq,
              name: page.name,
            });

            nextTick(() => {
              consoleRef.value.state.activeTab = consoleRef.value.tabs[0];
              consoleRef.value.commandRef.state.numbers = page.numbers;
              consoleRef.value.reloadComponents();
              closeModal();
            });
          } else {
            error();
          }
        })
        .catch(() => {
          error();
        });
    };

    const refresh = () => {
      if (!store.state.page.name) {
        return;
      }

      setNodeId("root");
      webviewRef.value.reload();
    };

    const keydownAlt = (key) => {
      if (!state.visible.console) {
        return;
      }

      if (key.startsWith("Digit")) {
        const keyNum = Number(key.replace("Digit", "")) - 1;

        if (consoleRef.value.tabs.length > keyNum) {
          consoleRef.value.selectTab(consoleRef.value.tabs[keyNum]);
        } else {
          return;
        }
      } else if (key.startsWith("Key")) {
        if (key === "KeyD") {
          consoleRef.value.fold();
        } else if (key === "KeyF") {
          consoleRef.value.setFullScreen();
        } else if (key === "KeyC") {
          consoleRef.value.commandRef.textareaRef.focus();
        } else if (key === "KeyA") {
          consoleRef.value.changePosition();
        } else if (key === "KeyR") {
          refresh();
        } else if (key === "KeyV") {
          toggleTag();
        } else if (key === "KeyM") {
          toggleMobile();
        } else if (key === "KeyZ") {
          undo();
        } else if (key === "KeyT") {
          switch (consoleRef.value?.state.activeTab) {
            case "components":
              consoleRef.value.componentBoxRef.focus();
              break;

            case "my-components":
              consoleRef.value.myComponentBoxRef.focus();
              break;

            // case "templates":
            //   consoleRef.value.templateBoxRef.focus();
            //   break;

            case "my-templates":
              consoleRef.value.myTemplateBoxRef.focus();
              break;
          }
        } else {
          return;
        }
      } else {
        return;
      }

      closeModal();
    };

    const keydownAltShift = (key) => {
      if (!state.visible.console) {
        return;
      }

      if (consoleRef.value.state.activeTab === "code") {
        const arr = ["!", "@", "#"];
        const idx = arr.indexOf(key);

        if (idx >= 0) {
          consoleRef.value.editorRef.selectLang(consoleRef.value.editorRef.langs[idx]);
        }
      }

      if (key === "KeyZ") {
        redo();
      }
    };

    const keyup = (key) => {
      switch (key) {
        case "ArrowRight":
          if (state.modal.name === "componentViewer") {
            componentViewerRef.value.move("next");
          } else if (state.modal.name === "imageViewer") {
            imageViewerRef.value.move("next");
          }
          break;

        case "ArrowLeft":
          if (state.modal.name === "componentViewer") {
            componentViewerRef.value.move("prev");
          } else if (state.modal.name === "imageViewer") {
            imageViewerRef.value.move("prev");
          }
          break;
      }
    };

    const setNodeId = (nid) => {
      store.commit("setPage", {
        ...store.state.page,
        nid: nid,
      });

      if (consoleRef.value.state.activeTab === "code") {
        consoleRef.value.editorRef.setCode();
      }
    };

    const undo = () => {
      if (!store.state.page.name) {
        return;
      }

      runCommand("undo");
    };

    const redo = () => {
      if (!store.state.page.name) {
        return;
      }

      runCommand("redo");
    };

    const runCommand = (command) => {
      consoleRef.value.commandRef.runCommands(command);
    };

    const toggleTag = () => {
      if (!store.state.page.name) {
        return;
      }

      consoleRef.value.toggleTagRef.change(!store.state.toggle.tag);
    };

    const toggleMobile = () => {
      if (!store.state.page.name) {
        return;
      }

      consoleRef.value.toggleMobileRef.change(!store.state.toggle.mobile);
    };

    const postback = (name, val1, val2, val3, val4) => {
      switch (name) {
        case "set-nids": {
          store.commit("setPage", {
            ...store.state.page,
            nids: val1,
          });
          break;
        }

        case "run-command":
          runCommand(val1);
          break;

        case "clear-assist-list":
          clearAssistList();
          break;

        case "complete-capture-node": {
          const args = {
            nid: val1,
            page: store.state.page.name,
            seq: store.state.page.seq,
            base64image: val2,
            category: val3,
            num: val4,
          };

          for (let i in args) {
            if (!args[i]) {
              return commLib.message.show("warning", store.state.site.lang === "ko" ? "컴포넌트가 존재하지 않습니다." : "The component does not exist.");
            }
          }

          const save = (force) => {
            if (force) {
              args.force = true;
            }

            httpLib
              .post("/api/components/save", args)
              .then(() => {
                if (args.nid === "root") {
                  commLib.message.show("success", store.state.site.lang === "ko" ? "템플릿을 저장하였습니다." : "The template has been saved.");
                  consoleRef.value.myTemplateBoxRef.state.init = false;

                  if (consoleRef.value.state.activeTab === "my-templates") {
                    consoleRef.value.myTemplateBoxRef.init();
                  }
                } else {
                  commLib.message.show("success", store.state.site.lang === "ko" ? "컴포넌트를 저장하였습니다." : "The component has been saved.");
                  consoleRef.value.myComponentBoxRef.state.init = false;

                  if (consoleRef.value.state.activeTab === "my-components") {
                    consoleRef.value.myComponentBoxRef.init();
                  }
                }

                setComponentCategories();
                renewResource();
              })
              .catch((err) => {
                switch (err.response?.status) {
                  case 400:
                    commLib.message.show("error", store.state.site.lang === "ko" ? "잘못된 요청입니다." : "Invalid request.");
                    break;

                  case 409:
                    if (confirm(store.state.site.lang === "ko" ? "이미 존재하는 컴포넌트입니다. 덮어쓰시겠습니까?" : "The name of a component that already exists. Overwrite?")) {
                      save(true);
                    }
                    break;

                  case 507:
                    commLib.message.show("warning", store.state.site.lang === "ko" ? "사용 가능한 리소스 크기를 초과하였습니다. 관리자에게 문의해주세요." : "The available resource size has been exceeded. Please contact the administrator.");
                    break;

                  default:
                    commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
                    break;
                }
              });
          };

          save();
          return;
        }

        case "click-nid":
          consoleRef.value.commandRef.state.text = `@${val1} `;
          consoleRef.value.commandRef.textareaRef.focus();
          setNodeId(val1);
          return;

        case "dblclick-nid":
          consoleRef.value.selectTab("code");
          setNodeId(val1);
          return;

        case "keydown-alt":
          keydownAlt(val1);
          return;

        case "keydown-alt-shift":
          keydownAltShift(val1);
          return;
      }
    };

    const setStyle = (obj) => {
      if (obj.paddingLeft !== undefined) {
        state.style.paddingLeft = obj.paddingLeft;
      }

      if (obj.paddingBottom !== undefined) {
        state.style.paddingBottom = obj.paddingBottom;
      }
    };

    const callback = (name, val1, val2, val3) => {
      switch (name) {
        case "go":
          go(val1);
          break;

        case "exit":
          location.hash = "";
          break;

        case "refresh":
          refresh();
          break;

        case "undo":
          undo();
          break;

        case "redo":
          redo();
          break;

        case "join":
          runCommand("join");
          break;

        case "login":
          runCommand("login");
          break;

        case "set-style":
          setStyle(val1);
          break;

        case "set-account":
          setAccount();
          break;

        case "set-pages":
          setPages();
          break;

        case "set-component-categories":
          setComponentCategories();
          break;

        case "renew-resource":
          renewResource();
          return;

        case "toggle-console":
          state.visible.console = !state.visible.console;

          if (state.visible.console) {
            commLib.cookie.set("consoleVisible", "Y");
          } else {
            setStyle({ paddingLeft: 0, paddingBottom: 0 });
            commLib.cookie.remove("consoleVisible");
          }
          break;

        case "open-modal":
          state.modal.name = val1;
          state.modal.size = val2 ? val2 : "normal";

          switch (state.modal.name) {
            case "componentViewer":
              state.modals.componentViewer.idx = val3.idx;
              state.modals.componentViewer.list = val3.list;
              state.modals.componentViewer.sector = val3.sector;
              break;

            case "imageViewer":
              state.modals.imageViewer.idx = val3.idx;
              state.modals.imageViewer.list = val3.list;
              state.modals.imageViewer.page = val3.page;
              break;

            case "videoViewer":
              state.modals.videoViewer.value = val3;
              break;

            case "code":
              state.modals.code.component = val3.component;
              state.modals.code.sector = val3.sector;
              state.modals.code.custom = val3.custom;
              break;
          }

          nextTick(() => {
            setTimeout(() => {
              document.querySelector(":focus")?.blur();
            });
          });
          break;

        case "set-nid": {
          setNodeId(val1);
          break;
        }

        case "keydown-alt":
          keydownAlt(val1);
          return;

        case "keydown-alt-shift":
          keydownAltShift(val1);
          return;

        case "close-modal":
          closeModal();
          break;
      }
    };

    // created
    httpLib.post("/api/visits", {
      url: location.href,
      ad: location.search && location.search.includes("google-ads"),
    });

    if (location.search) {
      location.replace("/" + store.state.site.lang);
      return;
    }

    if (consoleVisible === "Y") {
      state.visible.console = true;
    }

    if (webviewTag === "N") {
      store.commit("setToggle", {
        tag: false,
      });
    }

    if (mobileTag === "Y") {
      store.commit("setToggle", {
        mobile: true,
      });
    }

    document.addEventListener("click", (e) => {
      if (e.target) {
        const play = e.target.closest(".play-video");

        if (play) {
          const value = play.getAttribute("val");
          callback("open-modal", "videoViewer", "large", value);
        }

        clearAssistList();
      }
    });

    window.addEventListener("message", (e) => {
      postback(e.data.type, e.data.value1, e.data.value2, e.data.value3, e.data.value4);
    });

    window.addEventListener("keyup", (e) => {
      keyup(e.code);
    });

    window.addEventListener("keydown", (e) => {
      if (e.altKey) {
        if (e.shiftKey) {
          keydownAltShift(e.code);
        } else {
          keydownAlt(e.code);
        }

        if (e.code.startsWith("Digit") || e.code.startsWith("Key")) {
          e.preventDefault();
        }
      } else if (e.key === "Escape") {
        if (state.modal.name) {
          closeModal();
        }

        clearAssistList();
      }
    });

    window.onhashchange = () => {
      watch();
    };

    setAccount(
      () => {
        state.visible.ground = true;

        nextTick(() => {
          watch();
        });
      },
      () => {
        state.visible.error = true;
      }
    );

    return { store, state, consoleRef, webviewRef, componentViewerRef, imageViewerRef, videoViewerRef, closeModal, postback, callback, device };
  },
};
</script>
<style lang="scss" scoped>
.component-ground {
  > .ready {
    .modals {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.85);
      padding: 77px;
      z-index: 999;

      > .wrapper {
        width: 420px;
        max-width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        > .close-btn {
          position: absolute;
          cursor: pointer;
          top: -50px;
          right: 0;
          font-size: 42px;
          line-height: 42px;
          height: 50px;
          width: 50px;
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
          background: rgba(0, 0, 0, 0.21);
          transition: background 0.25s;

          &:hover {
            color: #fff;
            background: rgba(0, 0, 0, 0.42);
          }
        }

        > .inner {
          max-height: 100%;
          background: #fff;
          overflow: auto;
        }
      }

      &.large {
        > .wrapper {
          width: 1140px;
        }
      }

      &.long {
        padding: 50px;
      }

      &.full {
        > .wrapper {
          width: 100%;
        }
      }
    }
  }

  > .error {
    height: 100%;
    padding: 120px 0 20px 0;
    text-align: center;
    overflow: auto;

    > .text {
      margin: 35px 0;
      font-size: 25px;
    }
  }

  > .loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[page="opened"] {
    height: 100%;

    .ready {
      position: relative;
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    .ready .modals {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
</style>
