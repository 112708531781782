import { createWebHistory, createRouter } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('@/components/Main'),
    },
];

for (let l of ["ko", "en"]) {
    for (let m of ["intro", "learn", "components", "license", "community"]) {
        routes.push({
            path: `/${l}/${m}`,
            name: l + "-" + m,
            component: () => import('@/components/' + m[0].toUpperCase() + m.substring(1)),
        });
    }
}

routes.push({
    path: "/:catchAll(.*)",
    component: () => import('@/components/Main'),
});

export const router = createRouter({
    history: createWebHistory(),
    routes,
});