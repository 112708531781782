<template>
  <div class="modal-code">
    <div class="top">
      <span class="name">{{ data.component.category }} {{ data.component.num }}</span>
      <span class="desc">{{ data.component.desc[store.state.site.lang] }}</span>
    </div>
    <div class="inner clearfix">
      <div class="areas clearfix left">
        <div class="area frame">
          <pre><code class="language-html">{{ state.code.frame }}</code></pre>
          <div class="category">frame</div>
          <button class="btn btn-point" @click="commLib.copy(state.code.frame)">copy</button>
        </div>
        <div class="area html">
          <pre><code class="language-html">{{ state.code.html }}</code></pre>
          <div class="category">html</div>
          <button class="btn btn-point" @click="commLib.copy(state.code.html)">copy</button>
        </div>
      </div>
      <div class="areas clearfix">
        <div class="area css">
          <pre><code class="language-css">{{ state.code.css }}</code></pre>
          <div class="category">css</div>
          <button class="btn btn-point" @click="commLib.copy(state.code.css)">copy</button>
        </div>
        <div class="area javascript">
          <pre><code class="language-javascript">{{ state.code.javascript }}</code></pre>
          <div class="category">js</div>
          <button class="btn btn-point" @click="commLib.copy(state.code.javascript)">copy</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpLib from "../libs/httpLib";
import commLib from "../libs/commonLib";
import { nextTick, reactive } from "@vue/runtime-core";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import css from "highlight.js/lib/languages/css";
import html from "highlight.js/lib/languages/xml";
import "highlight.js/styles/vs2015.css";
import { useStore } from "vuex";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      code: {
        frame: "",
        html: "",
        css: "",
        javascript: "",
      },
    });

    const category = props.data.component.category.toString();

    const args = {
      category: category,
      num: props.data.component.num,
      custom: props.data.custom,
    };

    httpLib
      .get("/api/components/code", args)
      .then((res) => {
        state.code = res.data;

        hljs.registerLanguage("javascript", javascript);
        hljs.registerLanguage("css", css);
        hljs.registerLanguage("html", html);

        nextTick(() => {
          document.querySelectorAll("pre code").forEach((elem) => {
            hljs.highlightElement(elem);
          });
        });
      })
      .catch(() => {
        commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
      });

    return { store, state, commLib };
  },
};
</script>
<style lang="scss" scoped>
.modal-code {
  background: #1e1e1e;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #3c3c3c;

  .top {
    position: absolute;
    top: -60px;
    color: #fff;
    width: 100%;
    left: 0;
    font-size: 20px;
    padding: 15px 100px 15px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .name {
      font-size: 20px;
      display: inline-block;
      margin-right: 15px;
      font-weight: bold;
    }

    .desc {
      font-size: 14px;
    }
  }

  .inner {
    height: 100%;

    .areas {
      float: left;
      height: 100%;
      width: 50%;

      .area {
        float: left;
        position: relative;
        height: 50%;
        width: 100%;

        pre {
          height: 100%;
          overflow: auto;
          padding: 15px;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          code {
            white-space: pre;
            color: #fff;
          }

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: #1e1e1e;
          }

          &::-webkit-scrollbar-thumb {
            background: #aaa;
          }
        }

        .category {
          position: absolute;
          right: 21px;
          top: 14px;
          color: rgb(255 255 255 / 58%);
          font-size: 13px;
        }

        button {
          position: absolute;
          right: 15px;
          bottom: 15px;
          border-radius: 0;
          width: 54px;
          font-size: 13px;
        }

        &.frame {
          border-bottom: 1px solid #3c3c3c;
        }

        &.javascript {
          border-top: 1px solid #3c3c3c;
        }
      }

      &.left {
        border-right: 1px solid #3c3c3c;
      }
    }
  }
}
</style>
