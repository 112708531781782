<template>
  <div class="modal-video-viewer">
    <div class="ratio-video">
      <iframe
        width="100%"
        height="100%"
        title="Video Player"
        :src="`https://www.youtube.com/embed/${data.value}?list=PLtht1_et-35B58-NHHItPWOuyH6c3YhN0`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  setup() {},
};
</script>