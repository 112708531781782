<template>
  <div class="component-webview" :mobile="store.state.toggle.mobile ? 'Y' : 'N'" ref="rootRef">
    <div class="top">
      <div class="wrapper" :style="{ 'max-width': state.device.width }">
        <select class="form-control" v-model="state.device">
          <option :value="d" v-for="(d, idx) in devices" :key="idx">{{ d.name }} ({{ d.width }} / {{ d.height }})</option>
        </select>
        <button class="btn btn-default" title="Rotate" :class="{ rotated: state.rotated }" @click="state.rotated = !state.rotated">
          <i class="fa fa-mobile"></i>
        </button>
      </div>
    </div>
    <iframe
      ref="iframeRef"
      :src="initUrl"
      :class="{ 'dragging no-select': state.dragging }"
      :style="[store.state.toggle.mobile ? `${state.rotated ? `height:${state.device.width};max-width:${state.device.height}` : `max-width:${state.device.width};height:${state.device.height}`}` : '']"
    ></iframe>
    <div class="loading" v-if="!state.loaded">
      <Loading />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Loading from "./Loading";

export default {
  props: {
    page: String,
    postback: Function,
  },
  components: { Loading },
  setup(props) {
    const store = useStore();
    const state = reactive({
      init: false,
      device: {
        name: "",
        width: "",
        height: "",
      },
      dragging: false,
      loaded: false,
      rotated: false,
      visible: true,
      url: computed(() => `${store.state.site.url}/pages/${props.page}/${store.state.page.seq}?lang=${store.state.site.lang}`),
    });

    const rootRef = ref(null);
    const iframeRef = ref(null);
    const initUrl = `${store.state.site.url}/pages/init`;
    const devices = [
      { name: "Default", width: "420px", height: "800px" },
      { name: "Apple iPhone 12 Pro Max", width: "428px", height: "926px" },
      { name: "Apple iPhone 12 Pro", width: "390px", height: "844px" },
      { name: "Apple iPhone 12", width: "414px", height: "895px" },
      { name: "Apple iPhone 11", width: "390px", height: "844px" },
      { name: "Apple iPhone XR", width: "414px", height: "896px" },
      { name: "Apple iPhone XS", width: "375px", height: "812px" },
      { name: "Apple iPhone XS Max", width: "414px", height: "896px" },
      { name: "Apple iPhone X", width: "375px", height: "812px" },
      { name: "Samsung Galaxy S10", width: "360px", height: "760px" },
      { name: "Samsung Galaxy S10 Plus", width: "412px", height: "869px" },
      { name: "Samsung Galaxy S20", width: "360px", height: "800px" },
      { name: "Samsung Galaxy S20 Plus", width: "384px", height: "854px" },
      { name: "Samsung Galaxy S20 Ultra", width: "412px", height: "915px" },
      { name: "Samsung Galaxy S21 Ultra", width: "384px", height: "854px" },
    ];

    const load = (seq, max) => {
      if (seq === store.state.page.seq) {
        reload();
      } else {
        store.commit("setPage", {
          ...store.state.page,
          seq: seq,
        });
      }

      if (max && seq !== store.state.page.maxSeq) {
        store.commit("setPage", {
          ...store.state.page,
          maxSeq: seq,
        });
      }
    };

    const reload = () => {
      state.loaded = false;
      postMessage("reload");
    };

    const postMessage = (type, value) => {
      iframeRef.value?.contentWindow.postMessage(
        {
          type: type,
          value: value,
        },
        store.state.site.url
      );
    };

    state.device = devices[0];

    onMounted(() => {
      iframeRef.value.onload = () => {
        state.loaded = true;

        if (!state.init) {
          state.init = true;
          postMessage("location-replace", state.url);
        }
      };
    });

    const returnObj = { store, state, devices, rootRef, initUrl, iframeRef, load, reload, postMessage };
    store.commit("setRefs", { ...store.state.refs, webview: returnObj });
    return returnObj;
  },
  watch: {
    "state.url": {
      handler() {
        this.postMessage("location-replace", this.state.url);
        this.state.loaded = false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.component-webview {
  height: 100%;
  position: relative;

  > .top {
    display: none;
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;

    > .wrapper {
      margin: 0 auto;
      padding-right: 57.5px;
      position: relative;
      transition: height 0.25s, max-width 0.25s;

      select {
        height: 42px;
        border-radius: 0;
        border: 0;
        outline: 1px solid #ddd;
        font-size: 14px;

        &:focus {
          box-shadow: none;
          outline-color: #1259a7;
        }
      }

      button {
        width: 45px;
        background: #fff;
        box-shadow: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        text-align: center;
        padding: 0;
        border: 0;
        outline: 1px solid #ddd;
        font-size: 20px;

        i {
          transition: transform 0.25s;
        }

        &.rotated {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  > iframe {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border: 0;

    &.dragging {
      pointer-events: none;
    }
  }

  > .loading {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    margin-top: -100px;
  }

  &[mobile="Y"] {
    background: #f7f7f7;
    padding: 70px 0 0 0;
    position: relative;

    > .top {
      display: block;
    }

    > iframe {
      background: #fff;
      display: block;
      margin: 0 auto;
      outline: 1px solid #ddd;
      transition: height 0.25s, max-width 0.25s;
    }
  }
}
</style>
