<template>
  <div class="modal-comment">
    <div class="header">
      <div class="inner">
        <div class="title">
          <img src="/assets/img/ico.diamond.svg" />
          <span v-if="store.state.site.lang === 'ko'"><b>소중한 의견</b>을 남겨주세요!</span>
          <span v-else>Please leave your <b>valuable comments</b>!</span>
        </div>
      </div>
    </div>
    <div class="content">
      <Comment />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import Comment from "../components/Comment.vue";

export default {
  components: { Comment },
  setup() {
    const store = useStore();
    return { store };
  },
};
</script>
<style lang="scss" scoped>
$backgroundColor: #f7f7f7;

.modal-comment {
  background: $backgroundColor;

  > .header {
    position: sticky;
    background: $backgroundColor;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 15px;
    padding: 25px;
    z-index: 1;

    > .inner {
      margin-bottom: 5px;

      .title {
        float: left;
        padding: 0 5px 7.5px 2.5px;

        img {
          height: 35px;
          margin-right: 7.5px;
          vertical-align: bottom;
          margin-top: -7.5px;
        }
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }

  .content {
    background: #f7f7f7;
    padding: 0 25px 25px 25px;
  }
}
</style>
