<template>
  <div class="component-toggle" :class="{ active: state.active, disabled: disabled }" :title="title" @click="click">
    <div className="text">{{ text }}</div>
    <div class="inner">
      <div className="circle"></div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  props: {
    text: String,
    title: String,
    toggle: Function,
    disabled: Boolean,
    active: Boolean,
  },
  setup(props) {
    const state = reactive({
      active: props.active !== undefined ? props.active : true,
    });

    const change = (active) => {
      if (props.disabled) {
        return;
      }

      state.active = active;

      if (typeof props.toggle === "function") {
        props.toggle(state.active);
      }
    };

    const click = () => {
      if (props.disabled) {
        return;
      }

      change(!state.active);
    };

    return { state, change, click };
  },
};
</script>

<style lang="scss" scoped>
.component-toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;

  > div {
    display: inline-block;
    vertical-align: middle;

    &.text {
      margin-right: 6px;
    }

    &.inner {
      width: 30px;
      background: #777;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
      height: 15px;
      position: relative;
      border-radius: 7px;
      margin-top: -1px;

      .circle {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        border-radius: 50%;
        left: 0;
        background: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        transition: left 0.25s;
      }
    }
  }

  &.active {
    .inner {
      background: #146ece;

      .circle {
        left: 50%;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
