<template v-if="state.init">
  <div class="component-editor" :position="position" :lang="state.lang" :size="size">
    <div class="wrapper">
      <div ref="htmlRef" class="html area"></div>
      <div ref="cssRef" class="css area"></div>
      <div ref="javascriptRef" class="javascript area"></div>
    </div>
    <div class="addon">
      <ul>
        <li :class="{ active: state.lang === l }" :key="idx" v-for="(l, idx) in langs" @click="selectLang(l)" :title="l + ' (Alt + Shift + ' + (idx + 1) + ')'">{{ l === "javascript" ? "js" : l }}</li>
      </ul>
      <button class="btn btn-point" :title="(store.state.site.lang === 'ko' ? '코드 반영' : 'Submit') + ' (Ctrl + S)'" @click="submit">submit</button>
    </div>
  </div>
</template>
<script>
import loader from "@monaco-editor/loader";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import httpLib from "../libs/httpLib";
import commLib from "../libs/commonLib";

const monaco = {
  editor: null,
  instances: {
    html: "",
    css: "",
    javascript: "",
  },
};

export default {
  props: {
    page: String,
    callback: Function,
    position: String,
    size: String,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      init: false,
      force: false,
      code: {
        html: "",
        css: "",
        javascript: "",
      },
      lang: "html",
    });

    const langs = ["html", "css", "javascript"];
    const htmlRef = ref(null);
    const cssRef = ref(null);
    const javascriptRef = ref(null);

    const formatCode = () => {
      setTimeout(() => {
        for (let i in monaco.instances) {
          monaco.instances[i].setScrollPosition({ scrollTop: 0 });
          monaco.instances[i].getAction("editor.action.formatDocument").run();
        }
      }, 250);
    };

    const loadCode = (func) => {
      let args = {
        page: props.page,
        seq: store.state.page.seq,
        nid: store.state.page.nid,
      };

      httpLib.get("/api/pages/node", args).then((res) => {
        if (typeof res.data === "object" && Object.keys(res.data).length) {
          state.code = res.data;

          if (typeof func === "function") {
            func();
          }
        } else if (res.data === "none") {
          commLib.message.show("warning", store.state.site.lang === "ko" ? "해당 노드가 존재하지 않습니다." : "The node does not exist.");
        }
      });
    };

    const setCode = () => {
      loadCode(() => {
        if (!monaco.editor) {
          return;
        }

        const models = monaco.editor.editor.getModels();
        state.force = true;

        models[0].setValue(state.code.html);
        models[1].setValue(state.code.css);
        models[2].setValue(state.code.javascript);

        formatCode();
        setTimeout(() => {
          state.force = false;
        }, 500);
      });
    };

    const submit = () => {
      const args = {
        page: store.state.page.name,
        seq: store.state.page.seq,
        code: JSON.stringify(state.code),
      };

      httpLib
        .post("/api/pages/code", args)
        .then(() => {
          commLib.message.show("success", store.state.site.lang === "ko" ? "코드를 페이지에 반영하였습니다." : "The code has been applied to the page.");
          store.state.refs.webview.load(store.state.page.seq + 1, true);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 400:
              commLib.message.show("error", store.state.site.lang === "ko" ? "요청하신 코드를 반영할 수 없습니다." : "This code you sumitted could not be applied.");
              break;

            default:
              commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
              break;
          }
        });
    };

    const selectLang = (lang) => {
      state.lang = lang;
    };

    const init = (force) => {
      if (state.init && !force) {
        return;
      }

      loadCode(() => {
        loader.init().then((m) => {
          monaco.editor = m;

          for (let i = 0; i < langs.length; i += 1) {
            const lang = langs[i];
            const ref = lang === "html" ? htmlRef : lang === "css" ? cssRef : javascriptRef;
            const instance = m.editor.create(ref.value, {
              theme: "vs-dark",
              lineNumbers: "off",
              automaticLayout: true,
              renderIndentGuides: false,
              fixedOverflowWidgets: true,
              hover: {
                enabled: false,
              },
              minimap: {
                enabled: false,
              },
              padding: {
                top: 14,
              },
              value: state.code[lang],
              language: lang,
            });

            instance.onDidChangeModelContent(() => {
              const after = instance.getValue();

              if (["css", "javascript"].includes(lang)) {
                const before = state.code[lang];
                const comments = [];

                for (let b of before.split("\n")) {
                  if (lang === "css" && b.startsWith("/* @") && b.endsWith(" */")) {
                    comments.push(b);
                  } else if (lang === "javascript" && b.startsWith("// @")) {
                    comments.push(b);
                  }
                }

                for (let a of after.split("\n")) {
                  if (comments.includes(a)) {
                    comments.splice(comments.indexOf(a), 1);
                  }
                }

                if (comments.length && !state.force) {
                  const model = m.editor.getModels()[i];

                  if (!model) {
                    return;
                  }

                  model.setValue(before);
                  commLib.message.show("warning", store.state.site.lang === "ko" ? "해당 주석은 수정하거나 삭제할 수 없습니다." : "These comments cannot be edited or removed.");
                  return;
                }
              }

              state.code[lang] = after;
            });

            instance.addCommand(m.KeyMod.CtrlCmd | m.KeyCode.KEY_S, () => {
              submit();
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyCode.KEY_C, () => {
              props.callback("keydown-alt", "KeyC");
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyCode.KEY_P, () => {
              props.callback("keydown-alt", "KeyP");
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyCode.KEY_R, () => {
              props.callback("keydown-alt", "KeyR");
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyMod.Shift | m.KeyCode.KEY_1, () => {
              props.callback("keydown-alt-shift", "Digit1");
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyMod.Shift | m.KeyCode.KEY_2, () => {
              props.callback("keydown-alt-shift", "Digit2");
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyMod.Shift | m.KeyCode.KEY_3, () => {
              props.callback("keydown-alt-shift", "Digit3");
            });

            instance.addCommand(m.KeyMod.Alt | m.KeyCode.KEY_W, () => {});

            monaco.instances[lang] = instance;
          }

          formatCode();
        });
      });

      state.init = true;
    };

    return {
      store,
      state,
      htmlRef,
      cssRef,
      javascriptRef,
      langs,
      setCode,
      submit,
      selectLang,
      init,
    };
  },
};
</script>

<style lang="scss" scoped>
.component-editor {
  height: 100%;
  position: relative;
  overflow: hidden;

  > .wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;

    .area {
      display: none;
      height: 100%;
      position: relative;

      &:after {
        display: none;
        padding: 5px 10px;
        background: #393939;
        color: #fff;
        position: absolute;
        right: 15px;
        top: 0;
        font-size: 12px;
      }

      &.html:after {
        content: "html";
      }

      &.css:after {
        content: "css";
      }

      &.javascript:after {
        content: "js";
      }
    }
  }

  > .addon {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #262626;

    > ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        color: #aaa;
        cursor: pointer;
        font-size: 12px;
        position: relative;
        text-align: center;

        &.active {
          color: #fff;
          background: #1e1e1e;
        }
      }
    }

    > button {
      font-size: 14px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 0;
    }
  }

  &[position="side"] {
    padding-bottom: 42px;
    padding-right: 10px;

    > .addon {
      width: 100%;
      height: 42px;

      > ul > li {
        float: left;
        padding: 12px 20px;
      }

      > button {
        height: 100%;
      }
    }
  }

  &[position="bottom"] {
    padding-left: 77px;

    > .addon {
      width: 77px;
      height: 100%;

      > ul > li {
        width: 100%;
        padding: 12px;
      }

      > button {
        width: 100%;
        height: 42px;
      }
    }
  }

  &[lang="html"] {
    > .wrapper > .area.html {
      display: block;
    }
  }

  &[lang="css"] {
    > .wrapper > .area.css {
      display: block;
    }
  }

  &[lang="javascript"] {
    > .wrapper > .area.javascript {
      display: block;
    }
  }

  &[position="side"] {
    &[size="sm"],
    &[size="md"],
    &[size="lg"],
    &[size="xl"],
    &[size="xxl"] {
      > .wrapper {
        > .area {
          display: block;
          width: 50%;
          float: left;

          &.html {
            height: 100%;
            border-right: 1px solid #3c3c3c;
          }

          &.css {
            height: 50%;
            border-bottom: 1px solid #3c3c3c;
          }

          &.javascript {
            height: 50%;
          }

          &:after {
            display: inline-block;
          }
        }

        &:after {
          clear: both;
          content: " ";
          display: table;
        }
      }
    }
  }

  &[position="bottom"] {
    > .wrapper > .area {
      height: 100%;
      display: block;
      float: left;

      &.html {
        width: 40%;
      }

      &.css {
        width: 25%;
        border-left: 1px solid #3c3c3c;
        border-right: 1px solid #3c3c3c;
      }

      &.javascript {
        width: 35%;
      }
    }
  }
}
</style>
