<template>
  <div class="modal-image-viewer">
    <img :src="state.slide.url" @load="setStyle($event)" />
    <div class="angle left" title="Slide to prev" :style="state.angleStyle" @click="move('prev')">
      <img src="/assets/img/ico.angle.left.white.png" alt="Slide to prev" />
    </div>
    <div class="angle right" title="Slide to next" :style="state.angleStyle">
      <img src="/assets/img/ico.angle.right.white.png" @click="move('next')" alt="Slide to next" />
    </div>
    <div class="action" :title="store.state.site.lang === 'ko' ? '클립보드에 경로 복사' : 'Copy path to clipboard'">
      <i class="fa fa-clipboard" @click="copy(state.slide.url)"></i>
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import defines from "../defines.json";
import commLib from "../libs/commonLib";
import { useStore } from "vuex";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      slide: {
        idx: 0,
        url: "",
        list: [],
      },
      angleStyle: {
        display: "none",
        top: 0,
      },
    });

    const setStyle = (e) => {
      state.angleStyle.display = "block";
      state.angleStyle.top = e.target.parentNode.offsetHeight / 2 + "px";
    };

    const move = (direction) => {
      if (direction === "next" && state.slide.idx < state.slide.list.length - 1) {
        state.slide.idx += 1;
      } else if (direction === "prev" && state.slide.idx > 0) {
        state.slide.idx -= 1;
      } else {
        return;
      }

      state.slide.url = `${store.state.site.url}/files/${props.data.page}/${state.slide.list[state.slide.idx].name}`;
    };

    const copy = (url) => {
      commLib.copy(url);
    };

    onMounted(() => {
      const idx = props.data.idx;
      const list = props.data.list;
      state.slide.idx = idx;
      state.slide.url = `${store.state.site.url}/files/${props.data.page}/${list[idx].name}`;
      state.slide.list = list;
    });

    return { store, state, defines, move, copy, setStyle };
  },
};
</script>
<style lang="scss" scoped>
.modal-image-viewer {
  img {
    width: 100%;
  }

  .angle {
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -33.5px;
    display: none;

    &.left {
      left: -75px;
    }

    &.right {
      right: -75px;
    }
  }

  .action {
    position: absolute;
    top: -50px;
    right: 50px;

    i {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.21);
      font-size: 21px;
      text-align: center;
      margin-right: 1px;
      line-height: 50px;
      color: #fff;
      width: 50px;
    }
  }
}
</style>
